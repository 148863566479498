<template>
  <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
		<SubHeader/>
		<div class="d-flex flex-column-fluid">
      <!--begin::Container-->
      <div class="container">
        <!--begin::Profile Personal Information-->
        <div class="d-flex flex-row">
          <SideMenu/>
          <!--begin::Content-->
          <div class="flex-row-fluid ml-lg-8">
            <!--begin::Card-->
            <div class="card card-custom card-stretch">
              <!--begin::Header-->
              <div class="card-header py-3">
                <div class="card-title align-items-start flex-column">
                  <h3 class="card-label font-weight-bolder text-dark">Personal Information</h3>
                  <span class="text-muted font-weight-bold font-size-sm mt-1">Update your personal informaiton</span>
                </div>
                <div class="card-toolbar">
                  <button @click="submit" type="reset" class="btn btn-success">Save Changes</button>
                </div>
              </div>
              <!--end::Header-->
              <!--begin::Form-->
              <form class="form">
                <!--begin::Body-->
                <div class="card-body">
                  <div class="row">
                    <label class="col-xl-3"></label>
                    <div class="col-lg-9 col-xl-6">
                      <h5 class="font-weight-bold mb-6">Customer Info</h5>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-xl-3 col-lg-3 col-form-label">Avatar</label>
                    <div class="col-lg-9 col-xl-6">
                      <div class="image-input image-input-outline" id="kt_profile_avatar" style="background-image: url(/assets/media/users/blank.png)">
                        <img class="image-input-wrapper" :src="imageProfile"/>
                        <label class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow" data-action="change" data-toggle="tooltip" title="" data-original-title="Change avatar">
                          <i class="fa fa-pen icon-sm text-muted"></i>
                          <input type="file" @change="changeImageProfile" name="profile_avatar" accept=".png, .jpg, .jpeg" />
                          <input type="hidden" name="profile_avatar_remove" />
                        </label>
                        <span class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow" data-action="cancel" data-toggle="tooltip" title="Cancel avatar">
                          <i class="ki ki-bold-close icon-xs text-muted"></i>
                        </span>
                        <span @click="removeImageProfile" class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow" data-action="remove"  data-toggle="tooltip" title="Remove avatar">
                          <i class="ki ki-bold-close icon-xs text-muted"></i>
                        </span>
                      </div>
                      <span class="form-text text-muted">Allowed file types: png, jpg, jpeg.</span>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-xl-3 col-lg-3 col-form-label">First Name</label>
                    <div class="col-lg-9 col-xl-6">
                      <input class="form-control form-control-lg form-control-solid" type="text" v-model="firstName" />
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-xl-3 col-lg-3 col-form-label">Last Name</label>
                    <div class="col-lg-9 col-xl-6">
                      <input class="form-control form-control-lg form-control-solid" type="text" v-model="lastName" />
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-xl-3 col-lg-3 col-form-label">Address</label>
                    <div class="col-lg-9 col-xl-6">
                      <input class="form-control form-control-lg form-control-solid" type="text" v-model="address" />
                    </div>
                  </div>
                  <div class="row mb-4">
                    <label class="col-xl-3"></label>
                    <div class="col-lg-9 col-xl-6">
                      <h5 class="font-weight-bold mt-10 mb-6">Contact Info</h5>
                      <span class="form-text text-muted">We'll never share your personal info with anyone else.</span>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-xl-3 col-lg-3 col-form-label">Contact Phone</label>
                    <div class="col-lg-9 col-xl-6">
                      <div class="input-group input-group-lg input-group-solid">
                        <div class="input-group-prepend">
                          <span class="input-group-text">
                            <i class="la la-phone"></i>
                          </span>
                        </div>
                        <input type="text" class="form-control form-control-lg form-control-solid" v-model="phoneNumber" placeholder="Phone" />
                      </div>
                    </div>
                  </div>
                  
                  <!--begin::Group-->
                  <div class="form-group row">
                    <label class="col-xl-3 col-lg-3 col-form-label">Twitter</label>
                    <div class="col-lg-9 col-xl-6">
                      <div class="input-group input-group-lg input-group-solid">
                        <div class="input-group-prepend">
                          <span class="input-group-text">
                            <i class="la la-twitter"></i>
                          </span>
                        </div>
                        <input type="text" class="form-control form-control-lg form-control-solid" v-model="twitter" placeholder="@event_contact" />
                      </div>
                    </div>
                  </div>
                  <!--end::Group-->
                  <!--begin::Group-->
                  <div class="form-group row">
                    <label class="col-xl-3 col-lg-3 col-form-label">Facebook</label>
                    <div class="col-lg-9 col-xl-6">
                      <div class="input-group input-group-lg input-group-solid">
                        <div class="input-group-prepend">
                          <span class="input-group-text">
                            <i class="la la-facebook"></i>
                          </span>
                        </div>
                        <input type="text" class="form-control form-control-lg form-control-solid" v-model="facebook" placeholder="your facebook url" />
                      </div>
                    </div>
                  </div>
                  <!--end::Group-->





                </div>
                <!--end::Body-->
              </form>
              <!--end::Form-->
            </div>
          </div>
          <!--end::Content-->
        </div>
        <!--end::Profile Personal Information-->
      </div>
      <!--end::Container-->
    </div>
  </div>
</template>

<script>
import SideMenu from './SideMenu'
import SubHeader from './SubHeader3'
import { mapGetters } from 'vuex';
import userApi from '../../api/users';

export default {
  components: {
    SideMenu, SubHeader
  },
  data(){
    return {
      imageProfile: null,
      firstName: '',
      lastName: '',
      address: '',
      phoneNumber: '',
      twitter: '',
      facebook: '',
      imageProfileUpload: null
    };
  },
  methods: {
    removeImageProfile(){
      this.imageProfile = null;
    },
    changeImageProfile(e){
      const file = e.target.files[0];
      this.imageProfileUpload = file;
      this.imageProfile = URL.createObjectURL(file);
    }, 
    async submit(){
      this.$store.dispatch('setLoading', true);
      try{
        // 1. update profile image 
        if(this.imageProfileUpload){ // update
          await userApi.updateAvatar(this.imageProfileUpload);
        }else if(this.imageProfile == null){ // remove
          await userApi.deleteAvatar();
        }else{
          console.log('do not anything!');
        }
        // 2. update profile info
        const json = {
          first_name: this.firstName,
          last_name: this.lastName,
          address: this.address,
          organizer_url: 'whizdom-club'
        }
        await userApi.updateProfile(json);
        // 3. update contact
        if(this.user.contacts.length > 0){ // update first row
          const json = {
            phone: this.phoneNumber,
            twitter: this.twitter,
            facebook: this.facebook
          };
          await userApi.updateContact(this.user.id, this.idContact, json);
        } else {// create new row
          console.log('create new row');
        }
      }catch(error){
        this.$alert(error.response ? error.response.data.message : error.message, 'Submit Fail'
        , { confirmButtonText: 'OK', type: 'error' } );
        console.log(error);
      }finally{
        this.$store.dispatch('user/refreshUserInfo');
        this.$store.dispatch('setLoading', false);
      }
    },
    setUserModel(){
      this.imageProfile = this.user.avatar;
      this.firstName = this.user.firstName;
      this.lastName = this.user.lastName;
      this.address = this.user.address;
      this.twitter = this.user.contacts ? this.user.contacts[0].twitter : null;
      this.facebook = this.user.contacts ? this.user.contacts[0].facebook : null;
      this.idContact = this.user.contacts ? this.user.contacts[0]._id : null;
      this.phoneNumber = this.user.contacts ? this.user.contacts[0].phone : null;
    }
  },
  mounted(){
    this.setUserModel();
  },
  computed: {
    ...mapGetters({
      user: 'user/userInfo'
    })
  },
  watch: {
    user(){
      this.setUserModel();
    }
  }
}
</script>