<template>
	<div>
		<loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true" color="#FFC0CB" />

		<div v-if="isAuth">
		<!--begin::Main-->
			<div v-if="isDashBoard" id="app">
				<HeaderMobile/>
				<div class="d-flex flex-column flex-root">
					<!--begin::Page-->
					<div class="d-flex flex-row flex-column-fluid page">
						<Sidebar/>
						<!--begin::Wrapper-->
						<div class="d-flex flex-column flex-row-fluid wrapper" id="kt_wrapper">
							<Topbar/>
							<router-view></router-view>
							<Footer/>
						</div>
						<!--end::Wrapper-->
					</div>
					<!--end::Page-->
				</div>
				<!--end::Main-->
				<UserPanel/>
				<ScrollToTop/>
			</div>
			<div v-else>
				<router-view></router-view>
			</div>
		</div>
		<div v-else>
			<router-view></router-view>
		</div>
	</div>

</template>

<script>

import { mapGetters } from 'vuex'

import Sidebar from '@/components/Sidebar'
import Topbar from '@/components/Topbar'
import HeaderMobile from '@/components/HeaderMobile'
import UserPanel from '@/components/UserPanel'
import ScrollToTop from '@/components/ScrollToTop'
import Footer from '@/components/Footer'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
	name: 'App',
	data(){
		return {
			isDashBoard: true,
			isAuth: false
		};
	},
	components: {
		Sidebar, Topbar, HeaderMobile, UserPanel, ScrollToTop, Footer, Loading
	},
	computed: {
		...mapGetters({
			isLoading: 'isLoading',
			userInfo: 'user/userInfo'
		})
	},
	methods: {
		checkDashBoard(){
			if('QR' == this.$route.name){
				this.isDashBoard = false;
			} else {
				this.isDashBoard = true;
			}
		},
		checkAuth(){
			if(localStorage.getItem('token')){
				this.isAuth = true;
				if(!this.userInfo || !this.userInfo.id){
					this.$store.dispatch('user/refreshUserInfo');
				}
			}else{
				this.isAuth = false;
			}
		}
	},
	created() {
		this.checkAuth();
		this.checkDashBoard();
	},
	watch: {
		'$route.name'(){
			this.checkAuth();
			this.checkDashBoard();
		}
	}
}
</script>

<style>

</style>
