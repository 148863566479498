<template>
  <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
		<SubHeader :totalNumber="totalEvents"/>
		<div class="d-flex flex-column-fluid">
      <!--begin::Container-->
      <div class="container">
        <!--begin::Row-->
        <div class="row">
          <div class="col-xl-4" v-for="(event, index) in events" :key="index">
            <!--begin::Card-->
            <div class="card card-custom gutter-b card-stretch">
             
              <!--begin::Body-->
              <div class="card-body">
                <!--begin::Info-->
                <div class="d-flex align-items-center">
                  <!--begin::Pic-->
                  <div class="flex-shrink-0 mr-4 symbol symbol-60 symbol-circle">
                    <img :src="event.thumbnails 
                                ? event.thumbnails.length > 0 
                                ? event.thumbnails[0].url 
                                  : '/assets/images/event_placeholder.svg' 
                                    : '/assets/images/event_placeholder.svg'" 
                    alt="image" />
                  </div>
                  <!--end::Pic-->
                  <!--begin::Info-->
                  <div class="d-flex flex-column mr-auto">
                    <!--begin: Title-->
                    <div class="d-flex flex-column mr-auto">
                      <router-link :to="{name:'EventView', params: { id: event.id }}" class="text-dark text-hover-primary font-size-h4 font-weight-bolder mb-1">{{ event.title }}</router-link>
                      <span class="text-muted font-weight-bold">{{ event.location }}</span>
                    </div>
                    <!--end::Title-->
                  </div>
                  <!--end::Info-->
                </div>
                <!--end::Info-->
                <!--begin::Description-->
                <div class="mb-10 mt-5 font-weight-bold">{{ event.shortDescription }}</div>
                <!--end::Description-->
                <!--begin::Data-->
                <div class="d-flex mb-5">
                  <div class="d-flex align-items-center mr-7">
                    <span class="font-weight-bold mr-4">Start</span>
                    <span class="btn btn-light-primary btn-sm font-weight-bold btn-upper btn-text">{{ timeFormat(event.eventStart) }}</span>
                  </div>
                  <div class="d-flex align-items-center">
                    <span class="font-weight-bold mr-4">End</span>
                    <span class="btn btn-light-danger btn-sm font-weight-bold btn-upper btn-text">{{ timeFormat(event.eventEnd) }}</span>
                  </div>
                </div>
                <!--end::Data-->
                <!--begin::Progress-->
                <div class="d-flex mb-5 align-items-cente">
                  <span class="d-block font-weight-bold mr-5">Issued Quantity</span>
                  <div class="d-flex flex-row-fluid align-items-center">
                    <div class="progress progress-xs mt-2 mb-2 w-100">
                      <div class="progress-bar bg-success" role="progressbar" :style="`width: ${event.stats.issuedPercent == NaN ? 0 : event.stats.issuedPercent }%;`" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                    <span class="ml-3 font-weight-bolder">{{ event.stats.issued }}</span>
                  </div>
                </div>
                <!--ebd::Progress-->
              </div>
              <!--end::Body-->
              <!--begin::Footer-->
              <div class="card-footer d-flex align-items-center">
                <div class="d-flex">
                  <div class="d-flex align-items-center mr-7">
                    <span class="svg-icon svg-icon-gray-500">
                      <!--begin::Svg Icon | path:assets/media/svg/icons/Text/Bullet-list.svg-->
                      <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                          <rect x="0" y="0" width="24" height="24" />
                          <path d="M10.5,5 L19.5,5 C20.3284271,5 21,5.67157288 21,6.5 C21,7.32842712 20.3284271,8 19.5,8 L10.5,8 C9.67157288,8 9,7.32842712 9,6.5 C9,5.67157288 9.67157288,5 10.5,5 Z M10.5,10 L19.5,10 C20.3284271,10 21,10.6715729 21,11.5 C21,12.3284271 20.3284271,13 19.5,13 L10.5,13 C9.67157288,13 9,12.3284271 9,11.5 C9,10.6715729 9.67157288,10 10.5,10 Z M10.5,15 L19.5,15 C20.3284271,15 21,15.6715729 21,16.5 C21,17.3284271 20.3284271,18 19.5,18 L10.5,18 C9.67157288,18 9,17.3284271 9,16.5 C9,15.6715729 9.67157288,15 10.5,15 Z" fill="#000000" />
                          <path d="M5.5,8 C4.67157288,8 4,7.32842712 4,6.5 C4,5.67157288 4.67157288,5 5.5,5 C6.32842712,5 7,5.67157288 7,6.5 C7,7.32842712 6.32842712,8 5.5,8 Z M5.5,13 C4.67157288,13 4,12.3284271 4,11.5 C4,10.6715729 4.67157288,10 5.5,10 C6.32842712,10 7,10.6715729 7,11.5 C7,12.3284271 6.32842712,13 5.5,13 Z M5.5,18 C4.67157288,18 4,17.3284271 4,16.5 C4,15.6715729 4.67157288,15 5.5,15 C6.32842712,15 7,15.6715729 7,16.5 C7,17.3284271 6.32842712,18 5.5,18 Z" fill="#000000" opacity="0.3" />
                        </g>
                      </svg>
                      <!--end::Svg Icon-->
                    </span>
                    <span class="font-weight-bolder text-primary ml-2">{{ `${event.stats.issued}/${event.stats.maxAudience}` }}</span>
                  </div>
                  <div class="d-flex align-items-center mr-7">
                    <span class="svg-icon svg-icon-gray-500">
                      <!--begin::Svg Icon | path:assets/media/svg/icons/Communication/Group-chat.svg-->
                      <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                          <rect x="0" y="0" width="24" height="24" />
                          <path d="M16,15.6315789 L16,12 C16,10.3431458 14.6568542,9 13,9 L6.16183229,9 L6.16183229,5.52631579 C6.16183229,4.13107011 7.29290239,3 8.68814808,3 L20.4776218,3 C21.8728674,3 23.0039375,4.13107011 23.0039375,5.52631579 L23.0039375,13.1052632 L23.0206157,17.786793 C23.0215995,18.0629336 22.7985408,18.2875874 22.5224001,18.2885711 C22.3891754,18.2890457 22.2612702,18.2363324 22.1670655,18.1421277 L19.6565168,15.6315789 L16,15.6315789 Z" fill="#000000" />
                          <path d="M1.98505595,18 L1.98505595,13 C1.98505595,11.8954305 2.88048645,11 3.98505595,11 L11.9850559,11 C13.0896254,11 13.9850559,11.8954305 13.9850559,13 L13.9850559,18 C13.9850559,19.1045695 13.0896254,20 11.9850559,20 L4.10078614,20 L2.85693427,21.1905292 C2.65744295,21.3814685 2.34093638,21.3745358 2.14999706,21.1750444 C2.06092565,21.0819836 2.01120804,20.958136 2.01120804,20.8293182 L2.01120804,18.32426 C1.99400175,18.2187196 1.98505595,18.1104045 1.98505595,18 Z M6.5,14 C6.22385763,14 6,14.2238576 6,14.5 C6,14.7761424 6.22385763,15 6.5,15 L11.5,15 C11.7761424,15 12,14.7761424 12,14.5 C12,14.2238576 11.7761424,14 11.5,14 L6.5,14 Z M9.5,16 C9.22385763,16 9,16.2238576 9,16.5 C9,16.7761424 9.22385763,17 9.5,17 L11.5,17 C11.7761424,17 12,16.7761424 12,16.5 C12,16.2238576 11.7761424,16 11.5,16 L9.5,16 Z" fill="#000000" opacity="0.3" />
                        </g>
                      </svg>
                      <!--end::Svg Icon-->
                    </span>
                    <span class="font-weight-bolder text-primary ml-2">Used: {{ event.stats.confirmed }}</span>
                  </div>
                </div>
              </div>
              <!--end::Footer-->
            </div>
            <!--end:: Card-->
          </div>
          
          
        </div>
        <!--end::Row-->
        <!--begin::Pagination-->
        <div class="d-flex justify-content-between align-items-center flex-wrap">
          <div class="d-flex flex-wrap mr-3">
            <a @click="currentPage = 1" class="btn btn-icon btn-sm btn-light-primary mr-2 my-1">
              <i class="ki ki-bold-double-arrow-back icon-xs"></i>
            </a>
            <a @click="currentPage = currentPage == 1 ? currentPage : parseInt(currentPage) - 1" class="btn btn-icon btn-sm btn-light-primary mr-2 my-1">
              <i class="ki ki-bold-arrow-back icon-xs"></i>
            </a>
            <div v-for="(item, index) in totalPage" :key="item">
            <a 
              :class='{ "active": index + 1 == currentPage}' 
              @click="currentPage = index + 1"
              class="btn btn-icon btn-sm border-0 btn-hover-primary mr-2 my-1">
              {{ index + 1 }}
            </a>
            </div>
            <a @click="currentPage = currentPage == totalPage ? currentPage : parseInt(currentPage) + 1" class="btn btn-icon btn-sm btn-light-primary mr-2 my-1">
              <i class="ki ki-bold-arrow-next icon-xs"></i>
            </a>
            <a @click="currentPage = totalPage" class="btn btn-icon btn-sm btn-light-primary mr-2 my-1">
              <i class="ki ki-bold-double-arrow-next icon-xs"></i>
            </a>
          </div>
          <div class="d-flex align-items-center">
            <select v-model="perPage" class="form-control form-control-sm text-primary font-weight-bold mr-4 border-0 bg-light-primary" style="width: 75px;">
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="30">30</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
            <span class="text-muted">Displaying {{ perPage }} of {{ totalEvents }} records</span>
          </div>
        </div>
        <!--end::Pagination-->
      </div>
      <!--end::Container-->
    </div>
  </div>
</template>

<script>
import SubHeader from './SubHeader1'

// util
import moment from 'moment'
// api
import eventApi from '../../api/events'
// model
import Event from '../../models/event'

export default {
  data() {
    return {
      events: [],
      perPage: this.$route.query.perPage || 10,
      currentPage: this.$route.query.currentPage || 1,
      totalPage: 0,
      totalEvents: 0
    }
  },
  computed: {
    numberOfRow() {
      return (this.events.length % 3)
    },
    timeFormat(){
      return function (date) { 
        if(date){
          return moment(date).format('D MMM, yyyy')
        } else {
          return '-';
        } 
      }
    }
  },
  methods:{
    async getEvents(){
      this.$store.dispatch('setLoading', true);
      this.events = [];
      try{
        const response = await eventApi.getListEvent(this.perPage, this.currentPage);
        const events = response.data.data;
        const pagination = response.data.pagination;
        this.events = events.map(e => new Event(e));
        this.totalEvents = pagination.total;
        this.$store.dispatch('event/updateTotalEvents', this.totalEvents);
        this.totalPage = pagination.total_page;
        this.events.forEach(async e => {
          e.stats = await this.getStats(e.id);
        });
      }catch(error){
        console.log(error);
      }finally{this.$store.dispatch('setLoading', false);}
    },
    refresh(){
      this.$router.replace( { name: 'EventList', query: { perPage: this.perPage, currentPage: this.currentPage } });
      this.getEvents();
    },
    async getStats(id){
      const responseEventStats = await eventApi.getStats(id);
      const stats = responseEventStats.data.data;
      return {
        maxAudience: stats.max_audience,
        issued: stats.issued,
        issuedPercent: stats.issued_percent,
        confirmed: stats.confirmed,
        members: stats.members
      };
    }
  },
  mounted(){
    this.getEvents();
  },
  watch: {
    perPage(){
      this.refresh();
    },
    currentPage(){
      this.refresh();
    }
  },
  components: {
    SubHeader
  }
}
</script>