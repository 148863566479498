import axios from 'axios';

/**
 * Using for call ticket api 
 * - create ticket
 * - update ticket
 * - get ticket list by event id
 * - get ticket details
 * - delete ticket
 */
export default {
    createTicket (eventId, jsonData) {
        const headers = {
            'Content-Type': 'application/json'
        };
        return axios.post(`/events/${eventId}/tickets`, jsonData, { headers: headers });
    },
    getTicketList (eventId) {
        return axios.get(`/events/${eventId}/tickets`);
    },
    updateTicket (eventId, ticketId, jsonData) {
        const headers = {
            'Content-Type': 'application/json'
        };
        return axios.patch(`/events/${eventId}/tickets/${ticketId}`, jsonData, { headers: headers });
    },
    deleteTicket (eventId, ticketId) {
        return axios.delete(`/events/${eventId}/tickets/${ticketId}`);
    },
    showTicketBeforeAllowAccess (ticketId, myTicketId) {
        return axios.get(`/tickets/${ticketId}/buys/${myTicketId}`);
    },
    verifyTicket (ticketId, myTicketId, qrToken, quantity) {
        const headers = {
            'Content-Type': 'application/json'
        };
        const data = {
            quantity: quantity,
            token: qrToken
        };
        return axios.post(`/tickets/${ticketId}/buys/${myTicketId}/entry`, data, { headers: headers });
    },
    getWhoBought(eventId, perPage = 10, currentPage = 1) {
        const headers = {
            'Content-Type': 'application/json'
        };
        return axios.get(`/events/${eventId}/who-bought?per_page=${perPage}&current_page=${currentPage}`, {headers: headers}); 
    },
    getWhoEntries(eventId, perPage = 10, currentPage = 1) {
        const headers = {
            'Content-Type': 'application/json'
        };
        return axios.get(`/events/${eventId}/who-entries?per_page=${perPage}&current_page=${currentPage}`, {headers: headers}); 
    },
};