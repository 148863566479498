<template>
	<div class="content d-flex flex-column flex-column-fluid" id="kt_content">
		<div class="subheader py-2 py-lg-4 subheader-solid" id="kt_subheader">
    <div class="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
      <!--begin::Details-->
      <div class="d-flex align-items-center flex-wrap mr-2">
        <!--begin::Title-->
        <h5 class="text-dark font-weight-bold mt-2 mb-2 mr-5">Users</h5>
        <!--end::Title-->
        <!--begin::Separator-->
        <div class="subheader-separator subheader-separator-ver mt-2 mb-2 mr-5 bg-gray-200"></div>
        <!--end::Separator-->
        <!--begin::Search Form-->
        <div class="d-flex align-items-center" id="kt_subheader_search">
          <span class="text-dark-50 font-weight-bold" id="kt_subheader_total">{{totalUsers}} Total</span>
          <form class="ml-5">
            <div class="input-group input-group-sm input-group-solid" style="max-width: 175px">
              <input type="text" class="form-control" id="kt_subheader_search_form" placeholder="Search..." v-model="searchKeyWordUser" />
              <div class="input-group-append">
                <span class="input-group-text">
                  <span class="svg-icon">
                    <!--begin::Svg Icon | path:assets/media/svg/icons/General/Search.svg-->
                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                      <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <rect x="0" y="0" width="24" height="24" />
                        <path d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z" fill="#000000" fill-rule="nonzero" opacity="0.3" />
                        <path d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z" fill="#000000" fill-rule="nonzero" />
                      </g>
                    </svg>
                    <!--end::Svg Icon-->
                  </span>
                  <!--<i class="flaticon2-search-1 icon-sm"></i>-->
                </span>
              </div>
            </div>
          </form>
        </div>
        <!--end::Search Form-->
        <!--begin::Group Actions-->
        <div class="d-flex- align-items-center flex-wrap mr-2 d-none" id="kt_subheader_group_actions">
          <div class="text-dark-50 font-weight-bold">
          <span id="kt_subheader_group_selected_rows">23</span>Selected:</div>
          <div class="d-flex ml-6">
            <div class="dropdown mr-2" id="kt_subheader_group_actions_status_change">
              <button type="button" class="btn btn-light-primary font-weight-bolder btn-sm dropdown-toggle" data-toggle="dropdown">Update Status</button>
              <div class="dropdown-menu p-0 m-0 dropdown-menu-sm">
                <ul class="navi navi-hover pt-3 pb-4">
                  <li class="navi-header font-weight-bolder text-uppercase text-primary font-size-lg pb-0">Change status to:</li>
                  <li class="navi-item">
                    <a href="#" class="navi-link" data-toggle="status-change" data-status="1">
                      <span class="navi-text">
                        <span class="label label-light-success label-inline font-weight-bold">Approved</span>
                      </span>
                    </a>
                  </li>
                  <li class="navi-item">
                    <a href="#" class="navi-link" data-toggle="status-change" data-status="2">
                      <span class="navi-text">
                        <span class="label label-light-danger label-inline font-weight-bold">Rejected</span>
                      </span>
                    </a>
                  </li>
                  <li class="navi-item">
                    <a href="#" class="navi-link" data-toggle="status-change" data-status="3">
                      <span class="navi-text">
                        <span class="label label-light-warning label-inline font-weight-bold">Pending</span>
                      </span>
                    </a>
                  </li>
                  <li class="navi-item">
                    <a href="#" class="navi-link" data-toggle="status-change" data-status="4">
                      <span class="navi-text">
                        <span class="label label-light-info label-inline font-weight-bold">On Hold</span>
                      </span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <button class="btn btn-light-success font-weight-bolder btn-sm mr-2" id="kt_subheader_group_actions_fetch" data-toggle="modal" data-target="#kt_datatable_records_fetch_modal">Fetch Selected</button>
            <button class="btn btn-light-danger font-weight-bolder btn-sm mr-2" id="kt_subheader_group_actions_delete_all">Delete All</button>
          </div>
        </div>
        <!--end::Group Actions-->
      </div>
      <!--end::Details-->
      <!--begin::Toolbar-->
      <div class="d-flex align-items-center">
        <!--begin::Button-->
        <a href="#" class=""></a>
        <!--end::Button-->
        <!--begin::Button-->
        <router-link :to="{name:'UserCreate'}" class="btn btn-light-primary font-weight-bold btn-sm px-4 font-size-base ml-2">Add User</router-link>
        <!--end::Button-->
      </div>
      <!--end::Toolbar-->
    </div>
  </div>
		<!--begin::Entry-->
		<div class="d-flex flex-column-fluid">
			<!--begin::Container-->
			<div class="container">
				<!--begin::Card-->
				<div class="card card-custom gutter-b">
					<div class="card-header flex-wrap py-3">
						<div class="card-title">
							<h3 class="card-label">User List
							</h3>
						</div>
					</div>
					<div class="card-body">
						<table class="table">
								<thead>
										<tr>
												<th scope="col">#</th>
												<th scope="col">First</th>
												<th scope="col">Last</th>
												<th scope="col">Status</th>
												<th scope="col" width="160px"></th>
										</tr>
								</thead>
								<tbody>
										<tr v-for="(user, index) in allUsers" :key="index">
												<th scope="row" class="align-middle">{{ index+1 }}</th>
												<td class="align-middle">{{ user.firstName }}</td>
												<td class="align-middle">{{ user.lastName }}</td>
												<td class="align-middle">
														<span class="label label-inline label-light-success font-weight-bold" v-if="user.verified">
																Active
														</span>
														<span class="label label-inline label-light-primary font-weight-bold" v-else>
																Pending
														</span>
												</td>
												<td>
														<router-link :to="{name:'UserDetail'}" class="btn btn-icon btn-light btn-hover-primary btn-sm">
																<span class="svg-icon svg-icon-md svg-icon-primary">
																	<!--begin::Svg Icon | path:assets/media/svg/icons/General/Settings-1.svg-->
																	<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
																		<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
																			<rect x="0" y="0" width="24" height="24" />
																			<path d="M7,3 L17,3 C19.209139,3 21,4.790861 21,7 C21,9.209139 19.209139,11 17,11 L7,11 C4.790861,11 3,9.209139 3,7 C3,4.790861 4.790861,3 7,3 Z M7,9 C8.1045695,9 9,8.1045695 9,7 C9,5.8954305 8.1045695,5 7,5 C5.8954305,5 5,5.8954305 5,7 C5,8.1045695 5.8954305,9 7,9 Z" fill="#000000" />
																			<path d="M7,13 L17,13 C19.209139,13 21,14.790861 21,17 C21,19.209139 19.209139,21 17,21 L7,21 C4.790861,21 3,19.209139 3,17 C3,14.790861 4.790861,13 7,13 Z M17,19 C18.1045695,19 19,18.1045695 19,17 C19,15.8954305 18.1045695,15 17,15 C15.8954305,15 15,15.8954305 15,17 C15,18.1045695 15.8954305,19 17,19 Z" fill="#000000" opacity="0.3" />
																		</g>
																	</svg>
																	<!--end::Svg Icon-->
																</span>
															</router-link>
															<router-link :to="{name:'UserPersonal'}" class="btn btn-icon btn-light btn-hover-primary btn-sm mx-3">
																<span class="svg-icon svg-icon-md svg-icon-primary">
																	<!--begin::Svg Icon | path:assets/media/svg/icons/Communication/Write.svg-->
																	<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
																		<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
																			<rect x="0" y="0" width="24" height="24" />
																			<path d="M12.2674799,18.2323597 L12.0084872,5.45852451 C12.0004303,5.06114792 12.1504154,4.6768183 12.4255037,4.38993949 L15.0030167,1.70195304 L17.5910752,4.40093695 C17.8599071,4.6812911 18.0095067,5.05499603 18.0083938,5.44341307 L17.9718262,18.2062508 C17.9694575,19.0329966 17.2985816,19.701953 16.4718324,19.701953 L13.7671717,19.701953 C12.9505952,19.701953 12.2840328,19.0487684 12.2674799,18.2323597 Z" fill="#000000" fill-rule="nonzero" transform="translate(14.701953, 10.701953) rotate(-135.000000) translate(-14.701953, -10.701953)" />
																			<path d="M12.9,2 C13.4522847,2 13.9,2.44771525 13.9,3 C13.9,3.55228475 13.4522847,4 12.9,4 L6,4 C4.8954305,4 4,4.8954305 4,6 L4,18 C4,19.1045695 4.8954305,20 6,20 L18,20 C19.1045695,20 20,19.1045695 20,18 L20,13 C20,12.4477153 20.4477153,12 21,12 C21.5522847,12 22,12.4477153 22,13 L22,18 C22,20.209139 20.209139,22 18,22 L6,22 C3.790861,22 2,20.209139 2,18 L2,6 C2,3.790861 3.790861,2 6,2 L12.9,2 Z" fill="#000000" fill-rule="nonzero" opacity="0.3" />
																		</g>
																	</svg>
																	<!--end::Svg Icon-->
																</span>
															</router-link>
															<a href="#" class="btn btn-icon btn-light btn-hover-primary btn-sm">
																<span class="svg-icon svg-icon-md svg-icon-primary">
																	<!--begin::Svg Icon | path:assets/media/svg/icons/General/Trash.svg-->
																	<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
																		<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
																			<rect x="0" y="0" width="24" height="24" />
																			<path d="M6,8 L6,20.5 C6,21.3284271 6.67157288,22 7.5,22 L16.5,22 C17.3284271,22 18,21.3284271 18,20.5 L18,8 L6,8 Z" fill="#000000" fill-rule="nonzero" />
																			<path d="M14,4.5 L14,4 C14,3.44771525 13.5522847,3 13,3 L11,3 C10.4477153,3 10,3.44771525 10,4 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z" fill="#000000" opacity="0.3" />
																		</g>
																	</svg>
																	<!--end::Svg Icon-->
																</span>
															</a>
												</td>
										</tr>
								</tbody>
						</table>
						<!--begin::Pagination-->
						<div class="d-flex justify-content-between align-items-center flex-wrap">
										<div class="d-flex flex-wrap py-2 mr-3">
												<a @click="currentPage = 1" class="btn btn-icon btn-sm btn-light-primary mr-2 my-1">
												<i class="ki ki-bold-double-arrow-back icon-xs"></i>
												</a>
												<a @click="currentPage = currentPage == 1 ? currentPage : parseInt(currentPage) - 1" class="btn btn-icon btn-sm btn-light-primary mr-2 my-1">
												<i class="ki ki-bold-arrow-back icon-xs"></i>
												</a>
												<div v-for="(item, index) in totalPage" :key="item">
												<a 
												:class='{ "active": index + 1 == currentPage}' 
												@click="currentPage = index + 1"
												class="btn btn-icon btn-sm border-0 btn-hover-primary mr-2 my-1">
												{{ index + 1 }}
												</a>
												</div>
												
												<a @click="currentPage = currentPage == totalPage ? currentPage : parseInt(currentPage) + 1" class="btn btn-icon btn-sm btn-light-primary mr-2 my-1">
												<i class="ki ki-bold-arrow-next icon-xs"></i>
												</a>
												<a @click="currentPage = totalPage" class="btn btn-icon btn-sm btn-light-primary mr-2 my-1">
												<i class="ki ki-bold-double-arrow-next icon-xs"></i>
												</a>
										</div>
										<div class="d-flex align-items-center">
											<select v-model="perPage" class="form-control form-control-sm text-primary font-weight-bold mr-4 border-0 bg-light-primary" style="width: 75px;">
												<option value="10">10</option>
												<option value="20">20</option>
												<option value="30">30</option>
												<option value="50">50</option>
												<option value="100">100</option>
											</select>
											<span class="text-muted">Displaying {{ perPage > totalUsers ? totalUsers : perPage }} of {{ totalUsers }} records</span>
										</div>
								</div>
						<!--end:: Pagination-->
					</div>
				</div>
			</div>
			<!--end::Container-->
		</div>
		<!--end::Entry-->
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
	data() {
		return {
			currentPage: 1,
			perPage: 10,
			searchKeyWordUser: null
		}
	},
	methods: {
		getUsers(){
			const params = {perPage: this.perPage, currentPage: this.currentPage};
			this.$store.dispatch('user/refreshUsers', params);
		}
	},
	mounted() {
		this.getUsers()
	},
	computed:{
		...mapGetters({
			users: 'user/userList',
			totalUsers: 'user/totalUsers',
			totalPage: 'user/totalUsersPage'
		}),
		allUsers() {
			if(this.searchKeyWordUser) {
				return this.users.filter((item) => {
					return item.firstName.toLowerCase().includes(this.searchKeyWordUser.toLowerCase()) || item.lastName.toLowerCase().includes(this.searchKeyWordUser.toLowerCase());
				});
			}
			return this.users;
		}
	},
}
</script>