import axios from 'axios';

/**
 * Using for call event api 
 * - create event
 * - update event
 * - get events list 
 * - get event
 * - delete event
 */
export default {
    createEvent (jsonData) {
        const headers = {
            'Content-Type': 'application/json'
        };
        return axios.post('/events', jsonData, { headers: headers });
    },
    getEvent (id) {
        return axios.get('/events/' + id);
    },
    updateEvent (id, jsonData) {
        const headers = {
            'Content-Type': 'application/json'
        };
        return axios.patch('/events/' + id, jsonData, { headers: headers });
    },
    getListEvent (perPage = 10, currentPage = 1) {
        return axios.get(`/events?per_page=${perPage}&current_page=${currentPage}`);
    },
    deleteEvent (id) {
        return axios.delete('/events/' + id);
    },
    getCreateInfo () {
        return axios.get('/events/create');
    },
    uploadCovers (eventId, formData) {
        const headers = {
            'Content-Type': 'multipart/form-data'
        };
        return axios.post(`/events/${eventId}/covers`, formData, { headers: headers });
    },
    deleteGalleries (eventId, coverId) {
        return axios.delete(`/events/${eventId}/galleries/${coverId}`);
    },
    setTags (eventId, galleryId, tag) {
        const headers = {
            'Content-Type': 'application/json'
        };
        const json = {
            tags: [
                tag
            ]
        }
        return axios.patch(`/events/${eventId}/galleries/${galleryId}/tags/set`, json, { headers: headers });
    },
    getStats (eventId) {
        return axios.get(`/events/${eventId}/stats`);
    }
};