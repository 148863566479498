<template>
  <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
		<SubHeader @save="updateEvent"/>
		<!--begin::Entry-->
    <div class="container" style="height: 70vh" v-if="event == null">
      <div class="card card-custom gutter-b">
        <div class="card-body">
          <!-- Not found event -->
        </div>
      </div>
    </div>
		<div class="d-flex flex-column-fluid" v-else>
      <!--begin::Container-->
      <div class="container">
        <!--begin::Card-->
        <div class="card card-custom gutter-b">
          <div class="card-body">
            <div class="d-flex">
              <!--begin: Pic-->
              <div class="flex-shrink-0 mr-7 mt-lg-0 mt-3">
                <div class="symbol symbol-50 symbol-lg-120">
                  <img :src="event.thumbnails 
                                ? event.thumbnails.length > 0 
                                ? event.thumbnails[0].url 
                                  : '/assets/images/event_placeholder.svg' 
                                    : '/assets/images/event_placeholder.svg'" 
                    alt="image" />
                </div>
                <div class="symbol symbol-50 symbol-lg-120 symbol-primary d-none">
                  <span class="font-size-h3 symbol-label font-weight-boldest">JM</span>
                </div>
              </div>
              <!--end: Pic-->
              <!--begin: Info-->
              <div class="flex-grow-1">
                <!--begin: Title-->
                <div class="d-flex align-items-center justify-content-between flex-wrap">
                  <div class="mr-3">
                    <!--begin::Name-->
                    <a href="#" class="d-flex align-items-center text-dark text-hover-primary font-size-h5 font-weight-bold mr-3">{{event.title}}
                    <i class="flaticon2-correct text-success icon-md ml-2"></i></a>
                    <!--end::Name-->
                    <!--begin::Contacts-->
                    <div class="d-flex flex-wrap my-2">
                      <a href="#" class="text-muted text-hover-primary font-weight-bold">
                      <span class="svg-icon svg-icon-md svg-icon-gray-500 mr-1">
                        <!--begin::Svg Icon | path:assets/media/svg/icons/Map/Marker2.svg-->
                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                          <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <rect x="0" y="0" width="24" height="24" />
                            <path d="M9.82829464,16.6565893 C7.02541569,15.7427556 5,13.1079084 5,10 C5,6.13400675 8.13400675,3 12,3 C15.8659932,3 19,6.13400675 19,10 C19,13.1079084 16.9745843,15.7427556 14.1717054,16.6565893 L12,21 L9.82829464,16.6565893 Z M12,12 C13.1045695,12 14,11.1045695 14,10 C14,8.8954305 13.1045695,8 12,8 C10.8954305,8 10,8.8954305 10,10 C10,11.1045695 10.8954305,12 12,12 Z" fill="#000000" />
                          </g>
                        </svg>
                        <!--end::Svg Icon-->
                      </span>{{event.locationName }}</a>
                    </div>
                    <!--end::Contacts-->
                  </div>
                  <div class="my-lg-0 my-1">
                    <!-- <a href="#" class="btn btn-sm btn-light-success font-weight-bolder text-uppercase mr-3">Reports</a> -->
                  </div>
                </div>
                <!--end: Title-->
                <!--begin: Content-->
                <div class="d-flex align-items-center flex-wrap justify-content-between">
                  <div class="flex-grow-1 font-weight-bold text-dark-50 py-5 py-lg-2 mr-5">{{event.shortDescription}}</div>
                  <div class="d-flex flex-wrap align-items-center py-2">
                    <div class="d-flex align-items-center mr-10">
                      <div class="mr-6">
                        <div class="font-weight-bold mb-2">Start Date</div>
                        <span class="btn btn-sm btn-text btn-light-primary text-uppercase font-weight-bold">{{eventStart}}</span>
                      </div>
                      <div class="">
                        <div class="font-weight-bold mb-2">End Date</div>
                        <span class="btn btn-sm btn-text btn-light-danger text-uppercase font-weight-bold">{{eventEnd}}</span>
                      </div>
                    </div>
                    <div class="flex-grow-1 flex-shrink-0 w-150px w-xl-300px mt-4 mt-sm-0">
                      <span class="font-weight-bold">Issued</span>
                      <div class="progress progress-xs mt-2 mb-2">
                        <div class="progress-bar bg-success" role="progressbar" :style="`width: ${eventStats.issuedPercent}%;`" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                      </div>
                      <span class="font-weight-bolder text-dark">{{ eventStats.issuedPercent|checkNaN }}%</span>
                    </div>
                  </div>
                </div>
                <!--end: Content-->
              </div>
              <!--end: Info-->
            </div>
            <div class="separator separator-solid my-7"></div>
            <!--begin: Items-->
            <div class="d-flex align-items-center flex-wrap">
              <!--begin: Item-->
              <div class="d-flex align-items-center flex-lg-fill mr-5 my-1">
                <span class="mr-4">
                  <i class="flaticon-piggy-bank icon-2x text-muted font-weight-bold"></i>
                </span>
                <div class="d-flex flex-column text-dark-75">
                  <span class="font-weight-bolder font-size-sm">Max Quantity</span>
                  <span class="font-weight-bolder font-size-h5">
                  <span class="text-dark-50 font-weight-bold"></span>{{ eventStats.maxAudience }}</span>
                </div>
              </div>
              <!--end: Item-->
              <!--begin: Item-->
              <div class="d-flex align-items-center flex-lg-fill mr-5 my-1">
                <span class="mr-4">
                  <i class="flaticon-confetti icon-2x text-muted font-weight-bold"></i>
                </span>
                <div class="d-flex flex-column text-dark-75">
                  <span class="font-weight-bolder font-size-sm">Issued</span>
                  <span class="font-weight-bolder font-size-h5">
                  <span class="text-dark-50 font-weight-bold"></span>{{ eventStats.issued }}</span>
                </div>
              </div>
              <!--end: Item-->
              <!--begin: Item-->
              <div class="d-flex align-items-center flex-lg-fill mr-5 my-1">
                <span class="mr-4">
                  <i class="flaticon-pie-chart icon-2x text-muted font-weight-bold"></i>
                </span>
                <div class="d-flex flex-column text-dark-75">
                  <span class="font-weight-bolder font-size-sm">Confirmed</span>
                  <span class="font-weight-bolder font-size-h5">
                  <span class="text-dark-50 font-weight-bold"></span>{{ eventStats.confirmed }}</span>
                </div>
              </div>
              <!--end: Item-->
              <!--begin: Item-->
              <div class="d-flex align-items-center flex-lg-fill mr-5 my-1">
                <span class="mr-4">
                  <i class="flaticon-file-2 icon-2x text-muted font-weight-bold"></i>
                </span>
                <div class="d-flex flex-column flex-lg-fill">
                  <span class="font-weight-bolder font-size-sm">Member</span>
                  <span class="font-weight-bolder font-size-h5">
                  <span class="text-dark-50 font-weight-bold"></span>{{ eventStats.members }}</span>
                  <!-- <a href="#" class="text-primary font-weight-bolder">Export</a> -->
                </div>
              </div>
              <!--end: Item-->
              <!--begin: Item-->
              <!-- <div class="d-flex align-items-center flex-lg-fill mr-5 my-1">
                <span class="mr-4">
                  <i class="flaticon-chat-1 icon-2x text-muted font-weight-bold"></i>
                </span>
                <div class="d-flex flex-column">
                  <span class="text-dark-75 font-weight-bolder font-size-sm">16 Annonymous</span>
                  <a href="#" class="text-primary font-weight-bolder">Export</a>
                </div>
              </div> -->
              <!--end: Item-->
              <!--begin: Item-->
              <!-- <div class="d-flex align-items-center flex-lg-fill my-1">
                <span class="mr-4">
                  <i class="flaticon-network icon-2x text-muted font-weight-bold"></i>
                </span>
                <div class="symbol-group symbol-hover">
                  <div class="symbol symbol-30 symbol-circle" data-toggle="tooltip" title="Mark Stone">
                    <img alt="Pic" src="/assets/media/users/300_25.jpg" />
                  </div>
                  <div class="symbol symbol-30 symbol-circle" data-toggle="tooltip" title="Charlie Stone">
                    <img alt="Pic" src="/assets/media/users/300_19.jpg" />
                  </div>
                  <div class="symbol symbol-30 symbol-circle" data-toggle="tooltip" title="Luca Doncic">
                    <img alt="Pic" src="/assets/media/users/300_22.jpg" />
                  </div>
                  <div class="symbol symbol-30 symbol-circle" data-toggle="tooltip" title="Nick Mana">
                    <img alt="Pic" src="/assets/media/users/300_23.jpg" />
                  </div>
                  <div class="symbol symbol-30 symbol-circle" data-toggle="tooltip" title="Teresa Fox">
                    <img alt="Pic" src="/assets/media/users/300_18.jpg" />
                  </div>
                  <div class="symbol symbol-30 symbol-circle symbol-light">
                    <span class="symbol-label font-weight-bold">5+</span>
                  </div>
                </div>
              </div> -->
              <!--end: Item-->
            </div>
            <!--begin: Items-->
          </div>
        </div>
        <!--end::Card-->
        <!--begin::Card-->
        <div class="card card-custom">
          <!--begin::Card header-->
          <div class="card-header card-header-tabs-line nav-tabs-line-3x">
            <!--begin::Toolbar-->
            <div class="card-toolbar">
              <ul class="nav nav-tabs nav-bold nav-tabs-line nav-tabs-line-3x">
                <!--begin::Item-->
                <li class="nav-item mr-3">
                  <a class="nav-link" :class="{ 'active': !showTicketTab }" @click="$store.dispatch('event/updateShowTicketTab', false)" data-toggle="tab" href="#">
                    <span class="nav-icon">
                      <span class="svg-icon">
                        <!--begin::Svg Icon | path:assets/media/svg/icons/General/User.svg-->
                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                          <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <polygon points="0 0 24 0 24 24 0 24" />
                            <path d="M12,11 C9.790861,11 8,9.209139 8,7 C8,4.790861 9.790861,3 12,3 C14.209139,3 16,4.790861 16,7 C16,9.209139 14.209139,11 12,11 Z" fill="#000000" fill-rule="nonzero" opacity="0.3" />
                            <path d="M3.00065168,20.1992055 C3.38825852,15.4265159 7.26191235,13 11.9833413,13 C16.7712164,13 20.7048837,15.2931929 20.9979143,20.2 C21.0095879,20.3954741 20.9979143,21 20.2466999,21 C16.541124,21 11.0347247,21 3.72750223,21 C3.47671215,21 2.97953825,20.45918 3.00065168,20.1992055 Z" fill="#000000" fill-rule="nonzero" />
                          </g>
                        </svg>
                        <!--end::Svg Icon-->
                      </span>
                    </span>
                    <span class="nav-text font-size-lg">Event Detail</span>
                  </a>
                </li>
                <!--end::Item-->
                <!--begin::Item-->
                <li class="nav-item mr-3">
                  <a class="nav-link" :class="{ 'active': showTicketTab }" @click="$store.dispatch('event/updateShowTicketTab', true)" data-toggle="tab" href="#">
                    <span class="nav-icon">
                      <span class="svg-icon">
                        <!--begin::Svg Icon | path:assets/media/svg/icons/General/User.svg-->
                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                          <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <polygon points="0 0 24 0 24 24 0 24" />
                            <path d="M12,11 C9.790861,11 8,9.209139 8,7 C8,4.790861 9.790861,3 12,3 C14.209139,3 16,4.790861 16,7 C16,9.209139 14.209139,11 12,11 Z" fill="#000000" fill-rule="nonzero" opacity="0.3" />
                            <path d="M3.00065168,20.1992055 C3.38825852,15.4265159 7.26191235,13 11.9833413,13 C16.7712164,13 20.7048837,15.2931929 20.9979143,20.2 C21.0095879,20.3954741 20.9979143,21 20.2466999,21 C16.541124,21 11.0347247,21 3.72750223,21 C3.47671215,21 2.97953825,20.45918 3.00065168,20.1992055 Z" fill="#000000" fill-rule="nonzero" />
                          </g>
                        </svg>
                        <!--end::Svg Icon-->
                      </span>
                    </span>
                    <span class="nav-text font-size-lg">Design Ticket</span>
                  </a>
                </li>
                <!--end::Item-->
              </ul>
            </div>
            <!--end::Toolbar-->
          </div>
          <!--end::Card header-->
          <!--begin::Card body-->
          <div class="card-body">
            <form class="form" id="kt_form">
              <div class="tab-content">
                <!--begin::Tab-->
                <div class="tab-pane show px-7" :class="{ 'active': !showTicketTab }" id="kt_user_edit_tab_1" role="tabpanel">
                  <!--begin::Row-->
                  <div class="row">
                    <div class="col-xl-2"></div>
                    <div class="col-xl-7 my-2">
                      <!--begin::Row-->
                      <div class="row">
                        <label class="col-form-label col-3 text-lg-right text-left"></label>
                        <div class="col-9 mb-5">
                          <h6 class="text-dark font-weight-bold mb-0">Event Detail:</h6>
                          <span class="text-warning fs-6 mt-0">* We recommended image size is 768x1024 pixel or Ratio 3:4</span>
                        </div>
                      </div>
                      <!--end::Row-->
                      <!--begin::Group-->
                      <div class="form-group row">
                        <label class="col-form-label col-3 text-lg-right text-left">Header Image</label>
                        <div class="col-9">
                          <el-upload
                            :action="`${baseUrl}/events/${$route.params.id}/galleries`"
                            name="galleries"
                            :headers="headerRequest"
                            list-type="picture-card"
                            :on-preview="handleZoom"
                            :auto-upload="true"
                            :limit="3"
                            :on-exceed="handleExceed"
                            :file-list="covers"
                            :on-success="handleUploadHeaderImageSuccess"
                            :on-error="handleUploadHeaderImageError"
                            :on-remove="handleRemoveHeaderImage"
                            :before-remove="handleRemove">
                            <i class="el-icon-plus"></i>
                          </el-upload>
                          <el-dialog :visible.sync="dialogVisible">
                            <img width="100%" :src="dialogImageUrl" alt="">
                          </el-dialog>
                        </div>
                      </div>
                      <!--end::Group-->
                      <!--begin::Group-->
                      <div class="form-group row">
                        <label class="col-form-label col-3 text-lg-right text-left">Thumbnail Image</label>
                        <div class="col-9">
                          <el-upload
                            :action="`${baseUrl}/events/${$route.params.id}/galleries`"
                            name="galleries"
                            :headers="headerRequest"
                            list-type="picture-card"
                            :on-preview="handleZoom"
                            :auto-upload="true"
                            :limit="1"
                            :on-exceed="handleExceedThumbnail"
                            :file-list="thumbnails"
                            :on-success="handleUploadThumbnailImageSuccess"
                            :on-error="handleUploadThumbnailImageError"
                            :on-remove="handleRemoveHeaderImage"
                            :before-remove="handleRemove">
                            <i class="el-icon-plus"></i>
                          </el-upload>
                          <el-dialog :visible.sync="dialogVisible">
                            <img width="100%" :src="dialogImageUrl" alt="">
                          </el-dialog>
                        </div>
                      </div>
                      <!--end::Group-->
                      <!--begin::Group-->
                      <div class="form-group row">
                        <label class="col-form-label col-3 text-lg-right text-left">Title</label>
                        <div class="col-9">
                          <input class="form-control form-control-lg form-control-solid" type="text" v-model="form.title" placeholder="Event Title" />
                        </div>
                      </div>
                      <!--end::Group-->
                      <!--begin::Group-->
                      <div class="form-group row">
                        <label class="col-form-label col-3 text-lg-right text-left">Short Description</label>
                        <div class="col-9">
                          <input class="form-control form-control-lg form-control-solid" type="text" v-model="form.shortDescription" placeholder="" />
                        </div>
                      </div>
                      <!--end::Group-->
                      <!--begin::Group-->
                      <div class="form-group row">
                        <label class="col-form-label col-3 text-lg-right text-left">Description</label>
                        <div class="col-9">
                          <quill-editor ref="myTextEditor"
                            v-model="form.description"
                            :config="editorOption">
                          </quill-editor>
                        </div>
                      </div>
                      <!--end::Group-->
                      <!--begin::Group-->
                      <div class="form-group row">
                        <label class="col-form-label col-3 text-lg-right text-left">Important Note</label>
                        <div class="col-9">
                          <input class="form-control form-control-lg form-control-solid" type="text" v-model="form.importantNote" placeholder="Dress Code, Something to prepare, Don't be late!" />
                        </div>
                      </div>
                      <!--end::Group-->
                      <div class="separator separator-dashed my-10"></div>
                      <!--begin::Row-->
                      <div class="row">
                        <label class="col-form-label col-3 text-lg-right text-left"></label>
                        <div class="col-9">
                          <h6 class="text-dark font-weight-bold mb-10">Place</h6>
                        </div>
                      </div>
                      <!--begin::Group-->
                      <div class="form-group row">
                        <label class="col-form-label col-3 text-lg-right text-left">Name</label>
                        <div class="col-9">
                          <input class="form-control form-control-lg form-control-solid" type="text" v-model="form.locationName" placeholder="Place" />
                        </div>
                      </div>
                      <div class="form-group row">
                        <label class="col-form-label col-3 text-lg-right text-left">Url</label>
                        <div class="col-9">
                          <input class="form-control form-control-lg form-control-solid" type="text" v-model="form.locationUrl" placeholder="Place" />
                        </div>
                      </div>
                      <!--end::Group-->
                      <div class="separator separator-dashed my-10"></div>
                      <!--begin::Row-->
                      <div class="row">
                        <label class="col-form-label col-3 text-lg-right text-left"></label>
                        <div class="col-9">
                          <h6 class="text-dark font-weight-bold mb-10">Event Period:</h6>
                        </div>
                      </div>
                      <!--end::Row-->
                      <!--begin::Group-->
                      <div class="form-group row">
                        <label class="col-form-label text-right col-lg-3 col-sm-12">Begin - End</label>
                        <div class="col-lg-4 col-md-9 col-sm-12">
                          <div class="input-group date">
                            <vc-date-picker v-model="form.eventStart" mode="dateTime">
                              <template v-slot="{ inputValue, inputEvents }">
                                <input type="text" class="form-control form-control-lg form-control-solid" readonly  placeholder="Start Date/Time" :value="inputValue" v-on="inputEvents"/>
                              </template>
                            </vc-date-picker>
                          </div>
                        </div>
                        <div class="col-lg-4 col-md-9 col-sm-12">
                          <div class="input-group date">
                            <vc-date-picker v-model="form.eventEnd" mode="dateTime">
                              <template v-slot="{ inputValue, inputEvents }">
                                <input type="text" class="form-control form-control-lg form-control-solid" readonly  placeholder="End Date/Time" :value="inputValue" v-on="inputEvents"/>
                              </template>
                            </vc-date-picker>
                          </div>
                        </div>
                      </div>
                      <!--end::Group-->
                      <div class="separator separator-dashed my-10"></div>
                      <!--begin::Row-->
                      <div class="row">
                        <label class="col-form-label col-3 text-lg-right text-left"></label>
                        <div class="col-9">
                          <h6 class="text-dark font-weight-bold mb-10">Publish Period:</h6>
                        </div>
                      </div>
                      <!--end::Row-->
                      <!--begin::Group-->
                      <div class="form-group row">
                        <label class="col-form-label text-right col-lg-3 col-sm-12">Begin - End</label>
                        <div class="col-lg-4 col-md-9 col-sm-12">
                          <div class="input-group date">
                            <vc-date-picker v-model="form.publishStart" mode="dateTime">
                              <template v-slot="{ inputValue, inputEvents }">
                                <input type="text" class="form-control form-control-lg form-control-solid" readonly  placeholder="Start Date/Time" :value="inputValue" v-on="inputEvents"/>
                              </template>
                            </vc-date-picker>
                          </div>
                        </div>
                        <div class="col-lg-4 col-md-9 col-sm-12">
                          <div class="input-group date">
                            <vc-date-picker v-model="form.publishEnd" mode="dateTime">
                              <template v-slot="{ inputValue, inputEvents }">
                                <input type="text" class="form-control form-control-lg form-control-solid" readonly  placeholder="End Date/Time" :value="inputValue" v-on="inputEvents"/>
                              </template>
                            </vc-date-picker>
                          </div>
                        </div>
                      </div>
                      <!--end::Group-->
                      <div class="separator separator-dashed my-10"></div>
                      <div class="row">
                        <label class="col-form-label col-3 text-lg-right text-left"></label>
                        <div class="col-9">
                          <h6 class="text-dark font-weight-bold mb-10">Categories:</h6>
                        </div>
                      </div>
                      <!--begin::Group-->
                      <div v-for="c in categories" :key="c.id" class="form-group row">
                        <div class="col-3 text-right">
                          <input class="form-check-input" type="checkbox" :value="c.id" v-model="form.categoriesId">
                        </div>
                        <div class="col-9">
                          <label class="form-check-label" for="defaultCheck1">
                            {{ c.name }}
                          </label>
                        </div>
                      </div>
                      <!--end::Group-->
                      <div class="separator separator-dashed my-10"></div>
                      <!--begin::Row-->
                      <div class="row">
                        <label class="col-form-label col-3 text-lg-right text-left"></label>
                        <div class="col-9">
                          <h6 class="text-dark font-weight-bold mb-10">Contact:</h6>
                        </div>
                      </div>
                      <!--end::Row-->
                      <!--begin::Group-->
                      <div class="form-group row">
                        <label class="col-form-label col-3 text-lg-right text-left">Twitter</label>
                        <div class="col-9">
                          <div class="input-group input-group-lg input-group-solid">
                            <div class="input-group-prepend">
                              <span class="input-group-text">
                                <i class="la la-twitter"></i>
                              </span>
                            </div>
                            <input type="text" class="form-control form-control-lg form-control-solid" v-model="form.twitter" placeholder="@event_contact" />
                          </div>
                        </div>
                      </div>
                      <!--end::Group-->
                      <!--begin::Group-->
                      <div class="form-group row">
                        <label class="col-form-label col-3 text-lg-right text-left">Facebook</label>
                        <div class="col-9">
                          <div class="input-group input-group-lg input-group-solid">
                            <div class="input-group-prepend">
                              <span class="input-group-text">
                                <i class="la la-facebook"></i>
                              </span>
                            </div>
                            <input type="text" class="form-control form-control-lg form-control-solid" v-model="form.facebook" placeholder="your facebook url" />
                          </div>
                        </div>
                      </div>
                      <!--end::Group-->
                      <!--begin::Group-->
                      <div class="form-group row">
                        <label class="col-form-label col-3 text-lg-right text-left">Email Address</label>
                        <div class="col-9">
                          <div class="input-group input-group-lg input-group-solid">
                            <div class="input-group-prepend">
                              <span class="input-group-text">
                                <i class="la la-at"></i>
                              </span>
                            </div>
                            <input type="text" class="form-control form-control-lg form-control-solid" v-model="form.email" placeholder="your@email.com" />
                          </div>
                        </div>
                      </div>
                      <!--end::Group-->
                      <!--begin::Group-->
                      <div class="form-group row">
                        <label class="col-form-label col-3 text-lg-right text-left">Call</label>
                        <div class="col-9">
                          <div class="input-group input-group-lg input-group-solid">
                            <div class="input-group-prepend">
                              <span class="input-group-text">
                                <i class="la la-phone"></i>
                              </span>
                            </div>
                            <input type="text" class="form-control form-control-lg form-control-solid" v-model="form.mobile" placeholder="+66881234556" />
                          </div>
                        </div>
                      </div>
                      <!--end::Group-->

                      <div class="separator separator-dashed my-10"></div>
                      <div class="row">
                        <label class="col-form-label col-3 text-lg-right text-left"></label>
                        <div class="col-9">
                          <h6 class="text-dark font-weight-bold mb-7">Settings</h6>
                        </div>
                      </div>
                      <div class="form-group row">
                        <label class="col-form-label col-3 text-lg-right text-left">Type Can See</label>
                        <div class="col-9">
                           <div class="checkbox-inline mb-2" v-for="rt in this.residentTypes" :key="rt.id">
                            <label class="checkbox" >
                            <input class="form-check-input" type="checkbox" :id="rt.id" :value="rt.id" v-model="form.resident_types_id">
                            <span></span>{{rt.name}}</label>
                          </div>
                        </div>
                      </div>
                      <!--end::Group-->
                    </div>
                  </div>
                </div>
                <!--begin::Tab-->
                <TicketTypeList/>
                <!--end::Tab-->
                <!--begin::Tab-->
                <div class="tab-pane px-7" id="kt_user_edit_tab_3" role="tabpanel">
                  
                </div>
                <!--end::Tab-->
                <div v-show="errorMessage != ''" class="alert alert-danger" role="alert">
                      {{ errorMessage }}
                </div>
                <!--begin::Wizard Actions-->
                  <div class="d-flex justify-content-between border-top pt-10 mt-15">
                    <div class="mr-2">
                      <router-link :to="{name:'EventView'}" tag="button" type="button" id="prev-step" class="btn btn-light-primary font-weight-bolder px-9 py-4" data-wizard-type="action-prev">Cancel</router-link>
                    </div>
                    <div>
                      <button type="button" class="btn btn-success font-weight-bolder px-9 py-4" @click="updateEvent" data-wizard-type="action-submit">Save Changes</button>
                    </div>
                  </div>
                  <!--end::Wizard Actions-->
              </div>
            </form>
          </div>
        </div>
        <!--end::Card-->
      </div>
      <!--end::Container-->
    </div>
  </div>
</template>

<script>
import SubHeader from './SubHeader4'
import TicketTypeList from '@/views/ticketType/List'

/** configure Quill Editor
 *  https://quilljs.com/docs/configuration/
 *  https://github.com/surmon-china/vue-quill-editor
 * https://github.surmon.me/vue-quill-editor/
*/
import { quillEditor } from 'vue-quill-editor'

import eventApi from '../../api/events'
import Event from '../../models/event'
import moment from 'moment'
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      // quill
      name: 'app',
      content: '',
      editorOption: {},
      // v-calendar
      date: [
        [new Date(), new Date()]
      ],
      event: null,
      form: new Event(),
      errorMessage: '',

      // images
      headerImageURL: null,
      thumbnails: [],
      headerRequest: { 'api-key': process.env.VUE_APP_API_KEY, 'Authorization': 'Bearer ' + localStorage.getItem('token') },
      disabled: false,
      file: null,
      dialogVisible: false,
      dialogImageUrl: null,
      covers: [],
      imageUploaded: [], // mapping local uid and server id
      baseUrl: process.env.VUE_APP_BASE_URL,
      eventStats: {
        maxAudience: 0,
        issued: 0,
        issuedPercent: 0,
        confirmed: 0,
        members: 0,
        annonymous: 0
      }
    }
  },
  async mounted(){
    this.$store.dispatch('setLoading', true);
    const id = this.$route.params.id;
    try{
      await this.$store.dispatch('event/fetchEventInfo');
      const response = await eventApi.getEvent(id);
      this.event = new Event(response.data.data);
      this.form = Object.assign(this.event);
      this.covers = this.event.covers;
      this.thumbnails = this.event.thumbnails;
      const responseEventStats = await eventApi.getStats(id);
      const stats = responseEventStats.data.data;
      this.eventStats.maxAudience = stats.max_audience;
      this.eventStats.issued = stats.issued;
      this.eventStats.issuedPercent = stats.issued_percent;
      this.eventStats.confirmed = stats.confirmed;
      this.eventStats.members = stats.members;
    }catch(error){
      this.event = null;
      console.log(error);
    }finally{this.$store.dispatch('setLoading', false);}
  },
  methods: {
    async updateEvent(){
      this.$store.dispatch('setLoading', true);
      try{
        // TO-DO Mock up
        this.form.status = this.statusList[0];
        this.form.timezoneId = this.timezones.find(t => t.name == 'Asia/Bangkok').id;
        // -----------
        await eventApi.updateEvent(this.form.id, this.form.toJSON());
      }catch(error){
        this.errorMessage = error.response ? error.response.data.message : error.message;
        console.log(error);
      }finally{
        this.$store.dispatch('setLoading', false);
        this.$notify({
          title: 'Success',
          message: 'Update event successfully!',
          type: 'success'
        });
      }
    },
    handleUploadHeaderImage(e){
      console.log(e);
      this.$refs.uploadHeaderImage.submit();
    },
    handleZoom(file){
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    async handleRemove(fileSelected){
      if(fileSelected.id || fileSelected.response){ // call remove from api
        try{
          const imageId = fileSelected.id ? fileSelected.id : this.imageUploaded.find(i => i.uid === fileSelected.uid).id;
          await eventApi.deleteGalleries(this.$route.params.id, imageId);
          return true;
        }catch(error){
          await this.$message.error(`Fail: ${error.response ? error.response.data.message : error.message}`);
          throw error;
        }
      }else{
        console.log('delete local');
        return true;
      }
      
    },
    // handle upload header image
    async handleUploadHeaderImageSuccess(res, file) {
      // Update Tags
      try{
        console.log(file);
        const eventId = this.$route.params.id;
        const galleryId = res.data.galleries[res.data.galleries.length -1 ].id;
        await eventApi.setTags(eventId, galleryId, 'cover')
        this.imageUploaded.push({ uid: file.uid, id: galleryId});
      }catch(error) {
        console.log(error);
      }
    },
    handleExceed() {
        this.$message.warning(`จำกัดจำนวนอัพโหลดของรูปภาพ 3 ภาพเท่านั้น`);
    },
    handleExceedThumbnail(){
      this.$message.warning(`จำกัดจำนวนอัพโหลดของรูปภาพ 1 ภาพเท่านั้น`);
    },
    handleUploadHeaderImageError(error, file) {
      this.$message.error(`กำหนด URL สำหรับการ handle upload header ด้วยครับ! upload error ${error}, ${file}`)
    },
    beforeHeaderImageUpload(file) {
      const isLt10M = file.size / 1024 / 1024 < 10

      if (!isLt10M) {
        this.$message.error('Header Image size cannot exceed 10MB!')
      }
      return isLt10M
    },
    handleRemoveHeaderImage(file, fileList){
      console.log(file, fileList);
    },
    // handle upload thumbnail image
    async handleUploadThumbnailImageSuccess(res, file) {
      // Update Tags
      try{
        console.log(file);
        const eventId = this.$route.params.id;
        const galleryId = res.data.galleries[res.data.galleries.length -1 ].id;
        await eventApi.setTags(eventId, galleryId, 'thumbnail');
      }catch(error) {
        console.log(error);
      }
    },
    handleUploadThumbnailImageError(error, file) {
      this.$message.error(`กำหนด URL สำหรับการ handle upload thumbnail ด้วยครับ! upload error ${error}, ${file}`)
    },
    beforeThumbnailImageUpload(file) {
      const isPNG = file.type === 'image/png'
      const isLt10M = file.size / 1024 / 1024 < 10

      if (!isPNG) {
        this.$message.error('Thumbnail Image must be PNG format!')
      }

      if (!isLt10M) {
        this.$message.error('Thumbnail Image size cannot exceed 10MB!')
      }

      return isPNG && isLt10M
    }
  },
  computed: {
    eventStart(){
      return this.event.eventStart ? moment(this.event.eventStart).format('D MMM, yyyy') : '-';
    },
    eventEnd(){
      return this.event.eventEnd ? moment(this.event.eventEnd).format('D MMM, yyyy') : '-';
    },
    ...mapGetters({
      showTicketTab: 'event/isShowTicketTab',
      categories: 'event/categories',
      statusList: 'event/statusList',
      timezones: 'event/timezones',
      residentTypes: 'event/residentTypes'
    })
  },
  components: {
    SubHeader,
    TicketTypeList,
    quillEditor
  },
  watch: {
    'form.categoriesId'(){
      // console.log(value);
    }
  }
}
</script>

<style scoped>
  .image-uploader{
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    width: 148px;
  }
  .image-uploader:hover {
    border-color: #409eff;
  }
  .image-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 148px;
    height: 148px;
    line-height: 148px;
    /* text-align: center; */
  }
  .image {
    width: 148px;
    height: 148px;
    display: block;
  }
</style>