<template>
  <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
		<SubHeader :totalNumber="totalEvents"/>
		<div class="d-flex flex-column-fluid">
      <!--begin::Container-->
      <div class="container-fluid">
        <!--begin::Card-->
        <div class="card card-custom gutter-b">
          <div class="card-body">
            <div class="d-flex">
              <!--begin: Pic-->
              <div class="flex-shrink-0 mr-7 mt-lg-0 mt-3">
                <div class="symbol symbol-50 symbol-lg-120">
                  <img alt="Pic" :src="thumbnailUrl" />
                </div>
                <div class="symbol symbol-50 symbol-lg-120 symbol-primary d-none">
                  <span class="font-size-h3 symbol-label font-weight-boldest">JM</span>
                </div>
              </div>
              <!--end: Pic-->
              <!--begin: Info-->
              <div class="flex-grow-1">
                <!--begin: Title-->
                <div class="d-flex align-items-center justify-content-between flex-wrap">
                  <div class="mr-3">
                    <!--begin::Name-->
                    <a class="d-flex align-items-center text-dark text-hover-primary font-size-h5 font-weight-bold mr-3">{{event.title}}
                    <i class="flaticon2-correct text-success icon-md ml-2"></i></a>
                    <!--end::Name-->
                    <!--begin::Contacts-->
                    <div class="d-flex flex-wrap my-2">
                      <a :href="event.locationUrl" class="text-muted text-hover-primary font-weight-bold">
                      <span class="svg-icon svg-icon-md svg-icon-gray-500 mr-1">
                        <!--begin::Svg Icon | path:assets/media/svg/icons/Map/Marker2.svg-->
                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                          <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <rect x="0" y="0" width="24" height="24" />
                            <path d="M9.82829464,16.6565893 C7.02541569,15.7427556 5,13.1079084 5,10 C5,6.13400675 8.13400675,3 12,3 C15.8659932,3 19,6.13400675 19,10 C19,13.1079084 16.9745843,15.7427556 14.1717054,16.6565893 L12,21 L9.82829464,16.6565893 Z M12,12 C13.1045695,12 14,11.1045695 14,10 C14,8.8954305 13.1045695,8 12,8 C10.8954305,8 10,8.8954305 10,10 C10,11.1045695 10.8954305,12 12,12 Z" fill="#000000" />
                          </g>
                        </svg>
                        <!--end::Svg Icon-->
                      </span>{{event.locationName}}</a>
                    </div>
                    <!--end::Contacts-->
                  </div>
                  <div class="my-lg-0 my-1">
                    <a :href="previewUrl" target="_blank" class="btn btn-sm btn-light-info font-weight-bolder text-uppercase mr-3">Preview</a>
                  </div>
                </div>
                <!--end: Title-->
                <!--begin: Content-->
                <div class="d-flex align-items-center flex-wrap justify-content-between">
                  <div class="flex-grow-1 font-weight-bold text-dark-50 py-5 py-lg-2 mr-5">{{event.shortDescription}}</div>
                  <div class="d-flex flex-wrap align-items-center py-2">
                    <div class="d-flex align-items-center mr-10">
                      <div class="mr-6">
                        <div class="font-weight-bold mb-2">Start Date</div>
                        <span class="btn btn-sm btn-text btn-light-primary text-uppercase font-weight-bold">{{eventStart}}</span>
                      </div>
                      <div class="">
                        <div class="font-weight-bold mb-2">End Date</div>
                        <span class="btn btn-sm btn-text btn-light-danger text-uppercase font-weight-bold">{{eventEnd}}</span>
                      </div>
                    </div>
                    <div class="flex-grow-1 flex-shrink-0 w-150px w-xl-300px mt-4 mt-sm-0">
                      <span class="font-weight-bold">Issued</span>
                      <div class="progress progress-xs mt-2 mb-2">
                        <div class="progress-bar bg-success" role="progressbar" :style="`width: ${eventStats.issuedPercent}%;`" aria-valuemin="0" aria-valuemax="100"></div>
                      </div>
                      <span class="font-weight-bolder text-dark">{{ eventStats.issuedPercent|checkNaN }}%</span>
                    </div>
                  </div>
                </div>
                <!--end: Content-->
              </div>
              <!--end: Info-->
            </div>
            <div class="separator separator-solid my-7"></div>
            <!--begin: Items-->
            <div class="d-flex align-items-center flex-wrap">
              <!--begin: Item-->
              <div class="d-flex align-items-center flex-lg-fill mr-5 my-1">
                <span class="mr-4">
                  <i class="flaticon-piggy-bank icon-2x text-muted font-weight-bold"></i>
                </span>
                <div class="d-flex flex-column text-dark-75">
                  <span class="font-weight-bolder font-size-sm">Max Quantity</span>
                  <span class="font-weight-bolder font-size-h5">
                  <span class="text-dark-50 font-weight-bold"></span>{{ eventStats.maxAudience }}</span>
                </div>
              </div>
              <!--end: Item-->
              <!--begin: Item-->
              <div class="d-flex align-items-center flex-lg-fill mr-5 my-1">
                <span class="mr-4">
                  <i class="flaticon-confetti icon-2x text-muted font-weight-bold"></i>
                </span>
                <div class="d-flex flex-column text-dark-75">
                  <span class="font-weight-bolder font-size-sm">Issued</span>
                  <span class="font-weight-bolder font-size-h5">
                  <span class="text-dark-50 font-weight-bold"></span>{{ eventStats.issued }}</span>
                </div>
              </div>
              <!--end: Item-->
              <!--begin: Item-->
              <div class="d-flex align-items-center flex-lg-fill mr-5 my-1">
                <span class="mr-4">
                  <i class="flaticon-pie-chart icon-2x text-muted font-weight-bold"></i>
                </span>
                <div class="d-flex flex-column text-dark-75">
                  <span class="font-weight-bolder font-size-sm">Confirmed</span>
                  <span class="font-weight-bolder font-size-h5">
                  <span class="text-dark-50 font-weight-bold"></span>{{ eventStats.confirmed }}</span>
                </div>
              </div>
              <!--end: Item-->
              <!--begin: Item-->
              <div class="d-flex align-items-center flex-lg-fill mr-5 my-1">
                <span class="mr-4">
                  <i class="flaticon-file-2 icon-2x text-muted font-weight-bold"></i>
                </span>
                <div class="d-flex flex-column flex-lg-fill">
                  <span class="font-weight-bolder font-size-sm">Member</span>
                  <span class="font-weight-bolder font-size-h5">
                  <span class="text-dark-50 font-weight-bold"></span>{{ eventStats.members }}</span>
                  <!-- <a href="#" class="text-primary font-weight-bolder">Export</a> -->
                </div>
              </div>
              <!--end: Item-->
              <!--begin: Item-->
              <!-- <div class="d-flex align-items-center flex-lg-fill mr-5 my-1">
                <span class="mr-4">
                  <i class="flaticon-chat-1 icon-2x text-muted font-weight-bold"></i>
                </span>
                <div class="d-flex flex-column">
                  <span class="text-dark-75 font-weight-bolder font-size-sm">16 Annonymous</span>
                  <a href="#" class="text-primary font-weight-bolder">Export</a>
                </div>
              </div> -->
              <!--end: Item-->
              <!--begin: Item-->
              <!-- <div class="d-flex align-items-center flex-lg-fill my-1">
                <span class="mr-4">
                  <i class="flaticon-network icon-2x text-muted font-weight-bold"></i>
                </span>
                <div class="symbol-group symbol-hover">
                  <div class="symbol symbol-30 symbol-circle" data-toggle="tooltip" title="Mark Stone">
                    <img alt="Pic" src="/assets/media/users/300_25.jpg" />
                  </div>
                  <div class="symbol symbol-30 symbol-circle" data-toggle="tooltip" title="Charlie Stone">
                    <img alt="Pic" src="/assets/media/users/300_19.jpg" />
                  </div>
                  <div class="symbol symbol-30 symbol-circle" data-toggle="tooltip" title="Luca Doncic">
                    <img alt="Pic" src="/assets/media/users/300_22.jpg" />
                  </div>
                  <div class="symbol symbol-30 symbol-circle" data-toggle="tooltip" title="Nick Mana">
                    <img alt="Pic" src="/assets/media/users/300_23.jpg" />
                  </div>
                  <div class="symbol symbol-30 symbol-circle" data-toggle="tooltip" title="Teresa Fox">
                    <img alt="Pic" src="/assets/media/users/300_18.jpg" />
                  </div>
                  <div class="symbol symbol-30 symbol-circle symbol-light">
                    <span class="symbol-label font-weight-bold">5+</span>
                  </div>
                </div>
              </div> -->
              <!--end: Item-->
            </div>
            <!--begin: Items-->
          </div>
        </div>
        <!--end::Card-->
        <!--begin:Row-->
        <div class="row">
          <div class="col" v-for="ticket in tickets" :key="ticket.id">
            <!--begin::Stats Widget 1-->
            <div class="card card-custom bgi-no-repeat card-border card-stretch gutter-b ribbon ribbon-top" style="background-position: right top; background-size: 30% auto; background-image: url(/assets/media/svg/shapes/abstract-4.svg)">
              <div class="ribbon-target" :class="{ 'bg-secondary': ticket.type == 'unavailable' || ticket.type == 'hide' }" style="top: -2px; right: 20px;">{{ ticket.type }}</div>
              <!--begin::Body-->
              <div class="card-body">
                <a @click="openFormTicket(ticket)" style="cursor:pointer" class="card-title font-weight-bold text-muted text-hover-primary font-size-h5" data-toggle="modal" data-target="#exampleModalLong">{{ ticket.ticketTitle }}</a>
                <div class="font-weight-bold text-success mt-5 mb-1">Sale Period: 
                  <font class="font-weight-normal">
                    {{ formatTime(ticket.saleStart) }} - {{ formatTime(ticket.saleEnd) }}
                  </font>
                </div>
                <div class="font-weight-bold text-success mt-0 mb-5">Entry Period: 
                  <font class="font-weight-normal">
                    {{ formatTime(ticket.validStart) }} - {{ formatTime(ticket.validEnd) }}
                  </font>
                </div>
                <p class="text-dark-75 font-weight-bolder font-size-h5 m-0">
                  Max Quantity: {{ ticket.quantityPerOrder.value }}
                </p>
                <div class="font-weight-bold text-muted font-size-sm mt-4">
                  <span class="text-dark-75 font-size-h2 font-weight-bolder mr-2">{{ ticket.issued }}/{{ ticket.quantityTotal.value }}</span>Issued</div>
                  <div class="progress progress-xs mt-7 bg-success-o-60">
                  <div class="progress-bar bg-success" role="progressbar" :style="`width: ${ticket.issued / ticket.quantityTotal.value * 100}%;`" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
              </div>
              <!--end::Body-->
            </div>
            <!--end::Stats Widget 1-->
          </div>
          <div class="col">
            <!--begin::Engage Widget 2-->
            <div class="card card-custom card-stretch gutter-b">
              <div class="card-body d-flex p-0">
                <div class="flex-grow-1 bg-danger p-8 card-rounded flex-grow-1 bgi-no-repeat" style="background-position: calc(100% + 0.5rem) bottom; background-size: auto 70%; background-image: url(/assets/media/svg/humans/custom-3.svg)">
                  <h4 class="text-inverse-danger mt-2 font-weight-bolder">Design Your Ticket</h4>
                  <p class="text-inverse-danger my-6">You can design your ticket upto 4 types to separate your customer group</p>
                  <a @click="goUpdateTicket" class="btn btn-warning font-weight-bold py-2 px-6">Design a Ticket</a>
                </div>
              </div>
            </div>
            <!--end::Engage Widget 2-->
          </div>
        </div>
        <!--end::Row-->
        <!--begin::Row-->
        <div class="row">
          <div class="col-lg-8">
            <!--begin::Advance Table Widget 3-->
            <div class="card card-custom card-stretch gutter-b">
              <!--begin::Header-->
              <div class="card-header border-0 py-5">
                <h3 class="card-title align-items-start flex-column">
                  <span class="card-label font-weight-bolder text-dark">New Arrivals</span>
                  <span class="text-muted mt-3 font-weight-bold font-size-sm">More than {{ ticketEntriesTotal }} members</span>
                </h3>
                <div class="card-toolbar">
                  <div class="form-group" style="display:none;">
                    <div class="input-group">
                      <input type="text" class="form-control" placeholder="Search Arrivals"/>
                      <div class="input-group-append">
                        <button class="btn btn-primary" type="button">Go!</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!--end::Header-->
              <!--begin::Body-->
              <div class="card-body pt-0 pb-3">
                <div class="alert alert-custom alert-light-danger fade show mb-10" role="alert" v-if="ticketEntriesTotal <= 0">
                    <div class="alert-icon">
                      <span class="svg-icon svg-icon-3x svg-icon-danger">
                        <!--begin::Svg Icon | path:assets/media/svg/icons/Code/Info-circle.svg-->
                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                          <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <rect x="0" y="0" width="24" height="24" />
                            <circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10" />
                            <rect fill="#000000" x="11" y="10" width="2" height="7" rx="1" />
                            <rect fill="#000000" x="11" y="7" width="2" height="2" rx="1" />
                          </g>
                        </svg>
                        <!--end::Svg Icon-->
                      </span>
                    </div>
                    <div class="alert-text font-weight-bold">No Data</div>
                    <div class="alert-close">
                      <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">
                          <i class="ki ki-close"></i>
                        </span>
                      </button>
                    </div>
                  </div>
                <!--begin::Table-->
                <div class="table-responsive">
                  <table class="table table-head-custom table-head-bg table-borderless table-vertical-center">
                    <thead>
                      <tr class="text-uppercase">
                        <th style="min-width: 250px" class="pl-7">
                          <span class="text-dark-75">User</span>
                        </th>
                        <th style="min-width: 100px">Agent</th>
                        <th style="min-width: 150px">Scan At</th>
                        <th style="min-width: 130px">status</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(arrival, index) in ticketEntries" :key="index">
                        <td class="pl-0 py-8">
                          <div class="d-flex align-items-center">
                            <div class="symbol symbol-50 flex-shrink-0 mr-4">
                              <div class="symbol-label" v-bind:style="{'background-image': 'url('+avatarUrl(arrival.user.avatar)+')'}"></div>
                            </div>
                            <div>
                              <a href="#" class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">{{ arrival.user.firstName }} {{ arrival.user.lastName }}</a>
                              <span class="text-muted font-weight-bold d-block">{{ arrival.user.username }}</span>
                            </div>
                          </div>
                        </td>
                        <td>
                          <span class="text-dark-75 font-weight-bolder d-block font-size-lg">{{ arrival.confirmed_by.firstName }} {{ arrival.confirmed_by.lastName }}</span>
                          <span class="text-muted font-weight-bold">{{ arrival.confirmed_by.username }}</span>
                        </td>
                        <td>
                          <span class="text-dark-75 font-weight-bolder d-block font-size-lg">{{ formatScanDate(new Date(arrival.created_at.timestamp * 1000)) }}</span>
                          <span class="text-muted font-weight-bold">{{formatScanTime(new Date(arrival.created_at.timestamp * 1000))}}</span>
                        </td>
                        <td>
                          <span class="label label-lg label-light-success label-inline">{{formatStatus(arrival.status)}}</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <!--end::Table-->
              </div>
              <!--end::Body-->
              <div class="card-footer d-flex justify-content-between">
                <!--begin::Pagination-->
                <div class="d-flex justify-content-between align-items-center flex-wrap">
										<div class="d-flex flex-wrap py-2 mr-3">
												<a @click="currentPageTicketEntries = 1" class="btn btn-icon btn-sm btn-light-primary mr-2 my-1">
												<i class="ki ki-bold-double-arrow-back icon-xs"></i>
												</a>
												<a @click="currentPageTicketEntries = currentPageTicketEntries == 1 ? currentPageTicketEntries : parseInt(currentPageTicketEntries) - 1" class="btn btn-icon btn-sm btn-light-primary mr-2 my-1">
												<i class="ki ki-bold-arrow-back icon-xs"></i>
												</a>
												<div v-for="(item, index) in ticketEntriesTotalPage" :key="item">
												<a 
												:class='{ "active": index + 1 == currentPageTicketEntries}' 
												@click="currentPageTicketEntries = index + 1"
												class="btn btn-icon btn-sm border-0 btn-hover-primary mr-2 my-1">
												{{ index + 1 }}
												</a>
												</div>
												
												<a @click="currentPageTicketEntries = currentPageTicketEntries == ticketEntriesTotalPage ? currentPageTicketEntries : parseInt(currentPageTicketEntries) + 1" class="btn btn-icon btn-sm btn-light-primary mr-2 my-1">
												<i class="ki ki-bold-arrow-next icon-xs"></i>
												</a>
												<a @click="currentPageTicketEntries = ticketEntriesTotalPage" class="btn btn-icon btn-sm btn-light-primary mr-2 my-1">
												<i class="ki ki-bold-double-arrow-next icon-xs"></i>
												</a>
										</div>
										<div class="d-flex align-items-center">
											<select v-model="perPageTicketEntries" class="form-control form-control-sm text-primary font-weight-bold mr-4 border-0 bg-light-primary" style="width: 75px;">
												<option value="10">10</option>
												<option value="20">20</option>
												<option value="30">30</option>
												<option value="50">50</option>
												<option value="100">100</option>
											</select>
											<span class="text-muted">Displaying {{ perPageTicketEntries > ticketEntriesTotal ? ticketEntriesTotal : perPageTicketEntries }} of {{ ticketEntriesTotal }} records</span>
										</div>
								</div>
                <!--end:: Pagination-->
                <button type="button" class="btn btn-light-info" @click="downloadReport('entry-report')">Export</button>
              </div>
            </div>
            <!--end::Advance Table Widget 3-->
          </div>
          <div class="col-lg-4">
            <!--begin::List Widget 3-->
            <div class="card card-custom card-stretch gutter-b">
              <!--begin::Header-->
              <div class="card-header border-0 py-5">
                <h3 class="card-title align-items-start flex-column">
                  <span class="card-label font-weight-bolder text-dark">Who has Ticket</span>
                  <span class="text-muted mt-3 font-weight-bold font-size-sm">More than {{ticketOwnersTotal}} new members</span>
                </h3>
              </div>
              <!--end::Header-->
              <!--begin::Body-->
              <div class="card-body pt-2">
                <!--begin::Item-->
                <div class="alert alert-custom alert-light-danger fade show mb-10" role="alert" v-if="ticketOwnersTotal <= 0">
                    <div class="alert-icon">
                      <span class="svg-icon svg-icon-3x svg-icon-danger">
                        <!--begin::Svg Icon | path:assets/media/svg/icons/Code/Info-circle.svg-->
                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                          <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <rect x="0" y="0" width="24" height="24" />
                            <circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10" />
                            <rect fill="#000000" x="11" y="10" width="2" height="7" rx="1" />
                            <rect fill="#000000" x="11" y="7" width="2" height="2" rx="1" />
                          </g>
                        </svg>
                        <!--end::Svg Icon-->
                      </span>
                    </div>
                    <div class="alert-text font-weight-bold">No Data</div>
                    <div class="alert-close">
                      <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">
                          <i class="ki ki-close"></i>
                        </span>
                      </button>
                    </div>
                  </div>
                <div class="d-flex align-items-center mb-10" v-for="(ticketOwner, index) in ticketOwners" :key="index">
                  <!--begin::Symbol-->
                  <div class="symbol symbol-40 symbol-light-success mr-5">
                    <span class="symbol-label">
                      <img :src="avatarUrl(ticketOwner.avatar)" class="h-75 align-self-end" alt="User avatar" />
                    </span>
                  </div>
                  <!--end::Symbol-->
                  <!--begin::Text-->
                  <div class="d-flex flex-column flex-grow-1 font-weight-bold">
                    <a href="#" class="text-dark text-hover-primary mb-1 font-size-lg">{{ ticketOwner.user.firstName }} {{ ticketOwner.user.lastName }}</a>
                    <span class="text-muted">{{ ticketOwner.user.username }}</span>
                  </div>
                  <!--end::Text-->
                </div>
                <!--end::Item-->
              </div>
              <!--end::Body-->
              <div class="card-footer d-flex justify-content-between">
                <!--begin::Pagination-->
								<div class="d-flex justify-content-between align-items-center flex-wrap">
										<div class="d-flex flex-wrap py-2 mr-3">
												<a @click="currentPageTicketOwners = 1" class="btn btn-icon btn-sm btn-light-primary mr-2 my-1">
												<i class="ki ki-bold-double-arrow-back icon-xs"></i>
												</a>
												<a @click="currentPageTicketOwners = currentPageTicketOwners == 1 ? currentPageTicketOwners : parseInt(currentPageTicketOwners) - 1" class="btn btn-icon btn-sm btn-light-primary mr-2 my-1">
												<i class="ki ki-bold-arrow-back icon-xs"></i>
												</a>
												<div v-for="(item, index) in totalPageTicketOwners" :key="item">
												<a 
												:class='{ "active": index + 1 == currentPageTicketOwners}' 
												@click="currentPageTicketOwners = index + 1"
												class="btn btn-icon btn-sm border-0 btn-hover-primary mr-2 my-1">
												{{ index + 1 }}
												</a>
												</div>
												
												<a @click="currentPageTicketOwners = currentPageTicketOwners == totalPageTicketOwners ? currentPageTicketOwners : parseInt(currentPageTicketOwners) + 1" class="btn btn-icon btn-sm btn-light-primary mr-2 my-1">
												<i class="ki ki-bold-arrow-next icon-xs"></i>
												</a>
												<a @click="currentPageTicketOwners = totalPageTicketOwners" class="btn btn-icon btn-sm btn-light-primary mr-2 my-1">
												<i class="ki ki-bold-double-arrow-next icon-xs"></i>
												</a>
										</div>
										<div class="d-flex align-items-center">
											<select v-model="perPageTicketOwners" class="form-control form-control-sm text-primary font-weight-bold mr-4 border-0 bg-light-primary" style="width: 75px;">
												<option value="10">10</option>
												<option value="20">20</option>
												<option value="30">30</option>
												<option value="50">50</option>
												<option value="100">100</option>
											</select>
											<span class="text-muted">Displaying {{ perPageTicketOwners > ticketOwnersTotal ? ticketOwnersTotal : perPageTicketOwners }} of {{ ticketOwnersTotal }} records</span>
										</div>
								</div>
                <!--end:: Pagination-->
                <button type="button" class="btn btn-light-info" @click="downloadReport('purchase-report')">Export</button>
              </div>
            </div>
            <!--end::List Widget 3-->
          </div>
        </div>
        <!--end::Row-->
      </div>
      <!--end::Container-->
    </div>

    <!-- Modal -->
    <!-- Modal-->
    <div class="modal fade" id="modalTicketDetails" data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="staticBackdrop" aria-hidden="true">
        <div class="modal-dialog modal-xl" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Design your Ticket</h5>
                    <button @click="closeFormTicket" type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <i aria-hidden="true" class="ki ki-close"></i>
                    </button>
                </div>
                <div class="modal-body">
                    <TicketTypeCreate :modal="myModal"/>
                </div>
                <div class="modal-footer">
                    <button @click="closeFormTicket" type="button" class="btn btn-light-primary font-weight-bold" data-dismiss="modal">Close</button>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>
<script>
import SubHeader from './SubHeader2'
// util
import moment from 'moment'
// api
import eventApi from '../../api/events'
// model
import Event from '../../models/event'
import { mapGetters } from 'vuex'
import TicketTypeCreate from '../ticketType/Create'
import { Modal } from 'bootstrap';
import JsFileDownloader from 'js-file-downloader';

export default {
  data() {
    return {
      currentPageTicketOwners: 1,
			perPageTicketOwners: 10,
      currentPageTicketEntries: 1,
			perPageTicketEntries: 10,
      event: new Event(),
      myModal: null,
      eventStats: {
        maxAudience: 0,
        issued: 0,
        issuedPercent: 0,
        confirmed: 0,
        members: 0,
        annonymous: 0
      }
    }
  },
  methods: {
    downloadReport(type){
      let reportType = type == 'purchase-report' ? 'purchase-report' : 'entry-report';
      new JsFileDownloader({ 
        url: `${process.env.VUE_APP_BASE_URL}/events/${this.$route.params.id}/${reportType}`,
        headers: [
          { name: 'Authorization', value: 'Bearer ' + localStorage.getItem('token') },
          { name: 'api-key', value: process.env.VUE_APP_API_KEY }
        ],
        nameCallback: function(name) {
          return name + '.xlsx';
        }
      })
      .then(function () {
        console.log('download complete!');
      })
      .catch(function (error) {
        console.log(error);
      });
    },
    goUpdateTicket(){
      this.$store.dispatch('event/updateShowTicketTab', true);
      this.$router.push({ name: 'EventEdit'});
    },
    closeFormTicket(){
      this.$store.dispatch('ticket/setCurrentTicket', null)
      this.myModal.hide();
    },
    openFormTicket(ticket){
      this.$store.dispatch('ticket/setCurrentTicket', ticket)
      this.myModal.show();
    },
    formatTime(date){
      return date ? moment(date).format('LLL') : '-';
    },
    formatStatus(val){
      return val === 'confirmed' ? 'Approved' : 'Pending';
    },
    formatScanDate(date) {
        
        return date ? moment(date).format('DD MMM YY') : '-';  
    },
    formatScanTime(date) {
        return date ? moment(date).format('HH:mm') : '-';
    },
    avatarUrl(avatar){
      return avatar ? avatar : '/assets/images/event_placeholder.svg'; 
    }
  },
  async mounted(){
    this.myModal = new Modal(document.getElementById('modalTicketDetails'), {
        keyboard: false,
        backdrop: 'static'
      });
    this.$store.dispatch('setLoading', true);
    try{
      const id = this.$route.params.id;
      const response = await eventApi.getEvent(id);
      await this.$store.dispatch('ticket/fetchTickets', id);
      this.event = new Event(response.data.data);
      const params = {perPage: this.perPageTicketOwners, currentPage: this.currentPageTicketOwners, eventId: id};
      const paramsTicketEntries = {perPage: this.perPageTicketEntries, currentPage: this.currentPageTicketEntries, eventId: id};
      await this.$store.dispatch('ticket/fetchTicketOwners', params);
      await this.$store.dispatch('ticket/fetchTicketEntries', paramsTicketEntries)
      const responseEventStats = await eventApi.getStats(id);
      const stats = responseEventStats.data.data;
      this.eventStats.maxAudience = stats.max_audience;
      this.eventStats.issued = stats.issued;
      this.eventStats.issuedPercent = stats.issued_percent;
      this.eventStats.confirmed = stats.confirmed;
      this.eventStats.members = stats.members;
    }catch(error){
      console.log(error);
    }finally{this.$store.dispatch('setLoading', false)}
  },
  computed: {
    ...mapGetters({
      showTicketTab: 'event/isShowTicketTab',
      currentTicket: 'ticket/currentTicket',
      tickets: 'ticket/ticketList',
      ticketOwners: 'ticket/ticketOwners',
      ticketOwnersTotal: 'ticket/ticketOwnersTotal',
      totalPageTicketOwners: 'ticket/ticketOwnersTotalPage',
      ticketEntries: 'ticket/ticketEntries',
      ticketEntriesTotal: 'ticket/ticketEntriesTotal',
      ticketEntriesTotalPage: 'ticket/ticketEntriesTotalPage',      
      totalEvents: 'event/totalEvents',
    }),
    eventStart(){
      return this.event.eventStart ? moment(this.event.eventStart).format('D MMM, yyyy') : '-';
    },
    eventEnd(){
      return this.event.eventEnd ? moment(this.event.eventEnd).format('D MMM, yyyy') : '-';
    },
    previewUrl(){
      return `${process.env.VUE_APP_CUSTOMER_URL}/event/${this.$route.params.id}/preview`;
    },
    thumbnailUrl(){
      return this.event.thumbnails 
        ? this.event.thumbnails.length > 0 
        ? this.event.thumbnails[0].url : '/assets/images/event_placeholder.svg' : '/assets/images/event_placeholder.svg';
    }
  },
  components: {
    SubHeader,
    TicketTypeCreate
  },
}
</script>