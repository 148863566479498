<template>
  <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
		<SubHeader @submit="submitForm"/>
		<!--begin::Entry-->
		<div class="d-flex flex-column-fluid">
      <!--begin::Container-->
      <div class="container">
        <!--begin::Card-->
        <div class="card card-custom">
          <!--begin::Card header-->
          <div class="card-header card-header-tabs-line nav-tabs-line-3x">
            <!--begin::Toolbar-->
            <div class="card-toolbar">
              <ul class="nav nav-tabs nav-bold nav-tabs-line nav-tabs-line-3x">
                <!--begin::Item-->
                <li class="nav-item mr-3">
                  <a class="nav-link active" data-toggle="tab" href="#kt_user_edit_tab_1">
                    <span class="nav-icon">
                      <span class="svg-icon">
                        <!--begin::Svg Icon | path:assets/media/svg/icons/General/User.svg-->
                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                          <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <polygon points="0 0 24 0 24 24 0 24" />
                            <path d="M12,11 C9.790861,11 8,9.209139 8,7 C8,4.790861 9.790861,3 12,3 C14.209139,3 16,4.790861 16,7 C16,9.209139 14.209139,11 12,11 Z" fill="#000000" fill-rule="nonzero" opacity="0.3" />
                            <path d="M3.00065168,20.1992055 C3.38825852,15.4265159 7.26191235,13 11.9833413,13 C16.7712164,13 20.7048837,15.2931929 20.9979143,20.2 C21.0095879,20.3954741 20.9979143,21 20.2466999,21 C16.541124,21 11.0347247,21 3.72750223,21 C3.47671215,21 2.97953825,20.45918 3.00065168,20.1992055 Z" fill="#000000" fill-rule="nonzero" />
                          </g>
                        </svg>
                        <!--end::Svg Icon-->
                      </span>
                    </span>
                    <span class="nav-text font-size-lg">Event Detail</span>
                  </a>
                </li>
                <!--end::Item-->
              </ul>
            </div>
            <!--end::Toolbar-->
          </div>
          <!--end::Card header-->
          <!--begin::Card body-->
          <div class="card-body">
            <form class="form" id="kt_form">
              <div class="tab-content">
                <!--begin::Tab-->
                <div class="tab-pane show active px-7" id="kt_user_edit_tab_1" role="tabpanel">
                  <!--begin::Row-->
                  <div class="row">
                    <div class="col-xl-2"></div>
                    <div class="col-xl-7 my-2">
                      <!--begin::Row-->
                      <div class="row">
                        <label class="col-form-label col-3 text-lg-right text-left"></label>
                        <div class="col-9">
                          <h6 class="text-dark font-weight-bold mb-10">Event Detail:</h6>
                        </div>
                      </div>
                      <!--end::Row-->
                      <!--begin::Group-->
                      <div class="form-group row">
                        <label class="col-form-label col-3 text-lg-right text-left">Title</label>
                        <div class="col-9">
                          <input class="form-control form-control-lg form-control-solid" type="text" v-model="form.title" placeholder="Event Title" />
                        </div>
                      </div>
                      <!--end::Group-->
                      <!--begin::Group-->
                      <div class="form-group row">
                        <label class="col-form-label col-3 text-lg-right text-left">Short Description</label>
                        <div class="col-9">
                          <input class="form-control form-control-lg form-control-solid" type="text" v-model="form.shortDescription" placeholder="" />
                        </div>
                      </div>
                      <!--end::Group-->
                      <!--begin::Group-->
                      <div class="form-group row">
                        <label class="col-form-label col-3 text-lg-right text-left">Description</label>
                        <div class="col-9">
                          <quill-editor ref="myTextEditor"
                            v-model="form.description"
                            :config="editorOption">
                          </quill-editor>
                        </div>
                      </div>
                      <!--end::Group-->
                      <!--begin::Group-->
                      <div class="form-group row">
                        <label class="col-form-label col-3 text-lg-right text-left">Important Note</label>
                        <div class="col-9">
                          <input class="form-control form-control-lg form-control-solid" type="text" v-model="form.importantNote" placeholder="Dress Code, Something to prepare, Don't be late!" />
                        </div>
                      </div>
                      <!--end::Group-->
                      <div class="separator separator-dashed my-10"></div>
                      <!--begin::Row-->
                      <div class="row">
                        <label class="col-form-label col-3 text-lg-right text-left"></label>
                        <div class="col-9">
                          <h6 class="text-dark font-weight-bold mb-10">Place</h6>
                        </div>
                      </div>
                      <!--begin::Group-->
                      <div class="form-group row">
                        <label class="col-form-label col-3 text-lg-right text-left">Name</label>
                        <div class="col-9">
                          <input class="form-control form-control-lg form-control-solid" type="text" v-model="form.locationName" placeholder="Place" />
                        </div>
                      </div>
                      <div class="form-group row">
                        <label class="col-form-label col-3 text-lg-right text-left">Url</label>
                        <div class="col-9">
                          <input class="form-control form-control-lg form-control-solid" type="text" v-model="form.locationUrl" placeholder="Place" />
                        </div>
                      </div>
                      <!--end::Group-->
                      <div class="separator separator-dashed my-10"></div>
                      <!--begin::Row-->
                      <div class="row">
                        <label class="col-form-label col-3 text-lg-right text-left"></label>
                        <div class="col-9">
                          <h6 class="text-dark font-weight-bold mb-10">Event Period:</h6>
                        </div>
                      </div>
                      <!--end::Row-->
                      <!--begin::Group-->
                      <div class="form-group row">
                        <label class="col-form-label text-right col-lg-3 col-sm-12">Begin - End</label>
                        <div class="col-lg-4 col-md-9 col-sm-12">
                          <div class="input-group date">
                            <vc-date-picker v-model="form.eventStart" mode="dateTime">
                              <template v-slot="{ inputValue, inputEvents }">
                                <input type="text" class="form-control form-control-lg form-control-solid" readonly  placeholder="Start Date/Time" :value="inputValue" v-on="inputEvents"/>
                              </template>
                            </vc-date-picker>
                          </div>
                        </div>
                        <div class="col-lg-4 col-md-9 col-sm-12">
                          <div class="input-group date">
                            <vc-date-picker v-model="form.eventEnd" mode="dateTime">
                              <template v-slot="{ inputValue, inputEvents }">
                                <input type="text" class="form-control form-control-lg form-control-solid" readonly  placeholder="End Date/Time" :value="inputValue" v-on="inputEvents"/>
                              </template>
                            </vc-date-picker>
                          </div>
                        </div>
                      </div>
                      <!--end::Group-->
                      <div class="separator separator-dashed my-10"></div>
                      <!--begin::Row-->
                      <div class="row">
                        <label class="col-form-label col-3 text-lg-right text-left"></label>
                        <div class="col-9">
                          <h6 class="text-dark font-weight-bold mb-10">Publish Period:</h6>
                        </div>
                      </div>
                      <!--end::Row-->
                      <!--begin::Group-->
                      <div class="form-group row">
                        <label class="col-form-label text-right col-lg-3 col-sm-12">Begin - End</label>
                        <div class="col-lg-4 col-md-9 col-sm-12">
                          <div class="input-group date">
                            <vc-date-picker v-model="form.publishStart" mode="dateTime">
                              <template v-slot="{ inputValue, inputEvents }">
                                <input type="text" class="form-control form-control-lg form-control-solid" readonly  placeholder="Start Date/Time" :value="inputValue" v-on="inputEvents"/>
                              </template>
                            </vc-date-picker>
                          </div>
                        </div>
                        <div class="col-lg-4 col-md-9 col-sm-12">
                          <div class="input-group date">
                            <vc-date-picker v-model="form.publishEnd" mode="dateTime">
                              <template v-slot="{ inputValue, inputEvents }">
                                <input type="text" class="form-control form-control-lg form-control-solid" readonly  placeholder="End Date/Time" :value="inputValue" v-on="inputEvents"/>
                              </template>
                            </vc-date-picker>
                          </div>
                        </div>
                      </div>
                      <!--end::Group-->
                      <div class="separator separator-dashed my-10"></div>
                      <!--begin::Row-->
                      <div class="row">
                        <label class="col-form-label col-3 text-lg-right text-left"></label>
                        <div class="col-9">
                          <h6 class="text-dark font-weight-bold mb-10">Categories:</h6>
                        </div>
                      </div>
                      <!--begin::Group-->
                      <div v-for="c in categories" :key="c.id" class="form-group row">
                        <div class="col-3 text-right">
                          <input class="form-check-input" type="checkbox" :value="c.id" v-model="form.categoriesId">
                        </div>
                        <div class="col-9">
                          <label class="form-check-label" for="defaultCheck1">
                            {{ c.name }}
                          </label>
                        </div>
                      </div>
                      <!--end::Group-->
                      <div class="separator separator-dashed my-10"></div>
                      <!--begin::Row-->
                      <div class="row">
                        <label class="col-form-label col-3 text-lg-right text-left"></label>
                        <div class="col-9">
                          <h6 class="text-dark font-weight-bold mb-10">Contact:</h6>
                        </div>
                      </div>
                      <!--end::Row-->
                      <!--begin::Group-->
                      <div class="form-group row">
                        <label class="col-form-label col-3 text-lg-right text-left">Twitter</label>
                        <div class="col-9">
                          <div class="input-group input-group-lg input-group-solid">
                            <div class="input-group-prepend">
                              <span class="input-group-text">
                                <i class="la la-twitter"></i>
                              </span>
                            </div>
                            <input type="text" class="form-control form-control-lg form-control-solid" v-model="form.twitter" placeholder="@event_contact" />
                          </div>
                        </div>
                      </div>
                      <!--end::Group-->
                      <!--begin::Group-->
                      <div class="form-group row">
                        <label class="col-form-label col-3 text-lg-right text-left">Facebook</label>
                        <div class="col-9">
                          <div class="input-group input-group-lg input-group-solid">
                            <div class="input-group-prepend">
                              <span class="input-group-text">
                                <i class="la la-facebook"></i>
                              </span>
                            </div>
                            <input type="text" class="form-control form-control-lg form-control-solid" v-model="form.facebook" placeholder="your facebook url" />
                          </div>
                        </div>
                      </div>
                      <!--end::Group-->
                      <!--begin::Group-->
                      <div class="form-group row">
                        <label class="col-form-label col-3 text-lg-right text-left">Email Address</label>
                        <div class="col-9">
                          <div class="input-group input-group-lg input-group-solid">
                            <div class="input-group-prepend">
                              <span class="input-group-text">
                                <i class="la la-at"></i>
                              </span>
                            </div>
                            <input type="text" class="form-control form-control-lg form-control-solid" v-model="form.email" placeholder="your@email.com" />
                          </div>
                        </div>
                      </div>
                      <!--end::Group-->
                      <!--begin::Group-->
                      <div class="form-group row">
                        <label class="col-form-label col-3 text-lg-right text-left">Call</label>
                        <div class="col-9">
                          <div class="input-group input-group-lg input-group-solid">
                            <div class="input-group-prepend">
                              <span class="input-group-text">
                                <i class="la la-phone"></i>
                              </span>
                            </div>
                            <input type="text" class="form-control form-control-lg form-control-solid" v-model="form.mobile" placeholder="+66881234556" />
                          </div>
                        </div>
                      </div>
                      <!--end::Group-->
                      <div class="separator separator-dashed my-10"></div>
                      <div class="row">
                        <label class="col-form-label col-3 text-lg-right text-left"></label>
                        <div class="col-9">
                          <h6 class="text-dark font-weight-bold mb-7">Settings</h6>
                        </div>
                      </div>


                      <div class="form-group row">
                        <label class="col-form-label col-3 text-lg-right text-left">Type Can See</label>
                        <div class="col-9">
                          <div class="checkbox-inline mb-2" v-for="rt in this.residentTypes" :key="rt.id">
                            <label class="checkbox" >
                            <input class="form-check-input" type="checkbox" :id="rt.id" :value="rt.id" v-model="form.resident_types_id">
                            <span></span>{{rt.name}}</label>
                          </div>
                        </div>
                      </div>
                      <!--end::Group-->
                    </div>
                  </div>
                  <!--end::Row-->
                </div>
                <div v-show="errorMessage != ''" class="alert alert-danger" role="alert">
                      {{ errorMessage }}
                </div>
                <!--end::Tab-->
                <div class="d-flex justify-content-between border-top pt-10 mt-15">
                  <div class="mr-2">
                    <router-link :to="{name:'EventList'}" tag="button" type="button" id="prev-step" class="btn btn-light-primary font-weight-bolder px-9 py-4" data-wizard-type="action-prev">Cancel</router-link>
                  </div>
                  <div>
                    <button @click="submitForm" type="button" class="btn btn-success font-weight-bolder px-9 py-4" data-wizard-type="action-submit">Create Event</button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <!--end::Card-->
      </div>
      <!--end::Container-->
    </div>
  </div>
</template>

<script>
import SubHeader from './SubHeader3'

/** configure Quill Editor
 *  https://quilljs.com/docs/configuration/
 *  https://github.com/surmon-china/vue-quill-editor
 * https://github.surmon.me/vue-quill-editor/
*/
import { quillEditor } from 'vue-quill-editor'
import Event from '../../models/event'
import eventApi from '../../api/events'
import { mapGetters } from 'vuex'

export default {
  data() {
    return {

      // quill
      name: 'app',
      editorOption: {},
      // v-calendar
      date: [
        [new Date(), new Date()]
      ],
      form: new Event(),
      errorMessage: '',
      resident_types: []
    }
  },
  methods: {
    async submitForm()  {
      this.$store.dispatch('setLoading', true);
      this.errorMessage = '';
      try{
        // TO-DO Mock up
        this.form.status = this.statusList[0];
        this.form.timezoneId = this.timezones.find(t => t.name == 'Asia/Bangkok').id;
        // -----------
        const response = await eventApi.createEvent(this.form);
        const eventId = response.data.data.id;
        this.$router.push({ name: 'EventEdit', params: { id: eventId }});
      }catch(error){
        this.errorMessage = error.response ? error.response.data.message : error.message;
      }finally{this.$store.dispatch('setLoading', false);}
    }
  },
  components: {
    SubHeader, 
    quillEditor
  },
  computed: {
    ...mapGetters({
      categories: 'event/categories',
      statusList: 'event/statusList',
      timezones: 'event/timezones',
      residentTypes: 'event/residentTypes',
      defaultEventInformation: 'event/default'
    })
  },
  async mounted(){
    this.$store.dispatch('setLoading', true);
    try{
      await this.$store.dispatch('event/fetchEventInfo');
    }catch(error){
      this.errorMessage = error.response ? error.response.data.message : error.message;
    }finally{
      this.form.resident_types_id = this.defaultEventInformation?.restriction_types;
      this.$store.dispatch('setLoading', false);
    }
  }
}
</script>