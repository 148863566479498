<template>
    <div class="modal modal-active">
        <div class="modal-dialog" :class="isCenter ? 'modal-dialog-centered' : ''">
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" @click="$emit('close')" class="close"><span >&times;</span></button>
                        <h4 class="modal-title">
                            {{title}}
                        </h4>
                    </div>
                    <div class="modal-body">
                            <slot></slot>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-default" @click="$emit('close')">Close</button>
                    </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        isCenter: { type: Boolean, defalut: false },
        title: String
    }
}
</script>

<style>
.modal-active{
	display: block;
}
</style>