import usersApi from '../../api/users';
import Ticket from '../../models/ticket';
import User from '../../models/user';

const state = () => ({
    userInfo: null,
    myTickets: null,
    totalTickets: 0,
    totalPage: 0,
    userList: null,
    totalUsers: 0,
    totalUsersPage: 0
})

// getters
const getters = {
    userInfo: (state) => {
        return state.userInfo ? state.userInfo : new User();
    },
    myTickets: (state) => {
        return state.myTickets ? state.myTickets: null;
    },
    totalTickets: (state) => {
        return state.totalTickets ? state.totalTickets : 0;
    },
    totalPage: (state) => {
        return state.totalPage ? state.totalPage : 0;
    },
    userList: (state) => {
        return state.userList ? state.userList: null;
    },
    totalUsers: (state) => {
        return state.totalUsers ? state.totalUsers : 0;
    },
    totalUsersPage: (state) => {
        return state.totalUsersPage ? state.totalUsersPage : 0;
    }
}

// mutations
const mutations = {
    updateUserInfo (state, userInfo) {
        state.userInfo = userInfo;
    },
    updateMyTickets (state, myTickets) {
        state.myTickets = myTickets;
    },
    updateTotalTickets (state, totalTickets){
        state.totalTickets = totalTickets;
    },
    updateTotalPage (state, totalPage) {
        state.totalPage = totalPage;
    },
    updateUserList (state, userList) {
        state.userList = userList;
    },
    updateTotalUsers (state, totalUsers) {
        state.totalUsers = totalUsers;
    },
    updateTotalUsersPage (state, totalUsersPage) {
        state.totalUsersPage = totalUsersPage;
    }
}

// actions
const actions = {
    refreshUserInfo ({ commit }, token) {
        commit('setLoading', true, { root: true });
        return new Promise((resolve, reject) => {
            usersApi.getProfile(token)
                .then(response => {
                    const user = new User(response.data.data);
                    commit('user/updateUserInfo', user, { root: true })
                    resolve(response)
                })
                .catch(error => reject(error))
                .finally(() => commit('setLoading', false, { root: true }));
        });
    },
    refreshMyTickets ({commit}, thePayload) {
        commit('setLoading', true, {root: true});
        return new Promise((resolve, reject)=>{
            usersApi.getMyTickets(thePayload.perPage, thePayload.currentPage)
                .then(response => {
                    const tickets = [];
                    const responseData = response.data.data;
                    const totalTickets = response.data.pagination.total;
                    const totalPage = response.data.pagination.total_page;
                    responseData.forEach(element => {      
                        const ticket = new Ticket(element.ticket);
                        ticket.location = element.event.location;
                        ticket.buytime = element.created_at;
                        tickets.push(ticket);
                    });
                    commit('user/updateMyTickets', tickets, {root: true});
                    commit('user/updateTotalTickets', totalTickets, {root: true});
                    commit('user/updateTotalPage', totalPage, {root: true});
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setLoading', false, { root: true}));
        });
    },
    refreshUsers({commit}, thePayload) {
        commit('setLoading', true, {root: true});
        return new Promise((resolve, reject) => {
            usersApi.getUsers(thePayload.perPage, thePayload.currentPage)
                .then(response => {
                        const users = [];
                        const responseData = response.data.data;
                        const totalUsers = response.data.pagination.total;
                        const totalUsersPage = response.data.pagination.total_page;
                        responseData.forEach(element => {
                            const user = new User(element);
                            users.push(user);
                        });  
                        commit('user/updateUserList', users, {root:true});
                        commit('user/updateTotalUsers', totalUsers, {root:true});
                        commit('user/updateTotalUsersPage', totalUsersPage, {root:true});
                        resolve(response);
                    })
                    .catch(error => reject(error))
                    .finally(() => commit('setLoading', false, { root: true }));
        });
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}