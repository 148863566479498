<template>
  <!--begin::Subheader-->
  <div class="subheader py-2 py-lg-4 subheader-solid" id="kt_subheader">
    <div class="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
      <!--begin::Details-->
      <div class="d-flex align-items-center flex-wrap mr-2">
        <!--begin::Title-->
        <h5 class="text-dark font-weight-bold mt-2 mb-2 mr-5">Edit Event</h5>
        <!--end::Title-->
        <!--begin::Separator-->
        <div class="subheader-separator subheader-separator-ver mt-2 mb-2 mr-5 bg-gray-200"></div>
        <!--end::Separator-->
        <!--begin::Search Form-->
        <div class="d-flex align-items-center" id="kt_subheader_search">
          <span class="text-dark-50 font-weight-bold" id="kt_subheader_total">Save changes will affect immediatly</span>
        </div>
        <!--end::Search Form-->
      </div>
      <!--end::Details-->
      <!--begin::Toolbar-->
      <div class="d-flex align-items-center">
        <!--begin::Button-->
        <router-link :to="{name:'EventView'}" class="btn btn-default font-weight-bold">Back</router-link>
        <!--end::Button-->
        <!--begin::Dropdown-->
        <div class="btn-group ml-2">
          <button type="button" v-if="!showTicketTab" @click="saveChanges" class="btn btn-primary font-weight-bold">Save Changes</button>
        </div>
      </div>
      <!--end::Toolbar-->
    </div>
  </div>
  <!--end::Subheader-->
</template>

<script>

import { mapGetters } from 'vuex';

export default {
  methods:{
    saveChanges(){
      this.$emit('save');
    }
  },
  computed:{
    ...mapGetters({
      showTicketTab: 'event/isShowTicketTab',
    })
  }
};
</script>