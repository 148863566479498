export default class Event {
    constructor(json) {
        if (json) {
            this.id = json.id;
            this.title = json.title;
            this.shortDescription = json.short_description;
            this.description = json.description;
            this.importantNote = json.important_note;
            if (json.location) {
                this.locationName = json.location.detail;
                this.locationUrl = json.location.url;
            }
            this.eventStart = json.event_start ? new Date(json.event_start.timestamp * 1000) : null;
            this.eventEnd = json.event_end ? new Date(json.event_end.timestamp * 1000) : null;
            this.publishStart = json.publish_start ? new Date(json.publish_start.timestamp * 1000) : null;
            this.publishEnd = json.publish_end ? new Date(json.publish_end.timestamp * 1000) : null;
            this.twitter = json.contact ? json.contact.twitter : '';
            this.facebook = json.contact ? json.contact.facebook : '';
            this.email = json.contact ? json.contact.email : '';
            this.mobile = json.contact ? json.contact.phone : '';
            this.categoriesId = json.categories ? json.categories.map(c => c.id) : [];
            this.resident_types_id = json.restriction_types ? json.restriction_types.map(c => c.id) : [];
            if (json.galleries) {
                this.thumbnails = json.galleries.length > 0
                    ? json.galleries.filter(g => -1 !== g.tags.indexOf('thumbnail')).map(c => { return { id: c.id, url: c.url.original } })
                    : [];
                this.covers = json.galleries.length > 0
                    ? json.galleries.filter(g => -1 !== g.tags.indexOf('cover')).map(c => { return { id: c.id, url: c.url.original } })
                    : [];
            } else {
                this.thumbnails = [];
                this.covers = [];
            }
        } else {
            /**
             * TO-DO remove after just mock up
             */
            this.id = null;
            this.headerImage = null;
            this.title = '';
            this.shortDescription = '';
            this.description = '';
            this.importantNote = '';
            this.locationName = '';
            this.locationUrl = '';
            this.eventStart = new Date();
            this.eventEnd = new Date();
            this.publishStart = new Date();
            this.publishEnd = new Date();
            this.status = 'enable';
            this.twitter = '';
            this.facebook = '';
            this.email = '';
            this.mobile = '';
            this.covers = [];
            this.categoriesId = [];
            this.resident_types_id = [];
            this.thumbnail = [];
        }
        this.timezoneId = '';
        this.stats = {
            maxAudience: 0,
            issued: 0,
            issuedPercent: 0,
            confirmed: 0,
            members: 0
        };
    }

    // optional
    limitTicket = parseInt(Math.random() * 10) * 100 + parseInt(Math.random() * 10) * 100 + parseInt(Math.random() * 10) * 100;
    issued = parseInt(Math.random() * this.limitTicket);
    ratio = `${this.issued}/${this.limitTicket}`
    confirmed = parseInt(Math.random() * this.issued);

    toFormDataUploadImage () {
        const formData = new FormData();
        formData.append('image', this.headerImage);
        formData.append('thumbnail', this.thumbnail);
        return formData;
    }

    toJSON () {
        return {
            title: this.title,
            description: this.description,
            short_description: this.shortDescription,
            important_note: this.importantNote,
            location: {
                detail: this.locationName,
                url: this.locationUrl
            },
            event_start: this.eventStart.toISOString(),
            event_end: this.eventEnd.toISOString(),
            publish_start: this.publishStart.toISOString(),
            publish_end: this.publishEnd.toISOString(),
            status: this.status,
            twitter: this.twitter,
            facebook: this.facebook,
            email: this.email,
            mobile: this.mobile,
            timezone_id: this.timezoneId,
            categories_id: this.categoriesId,
            restriction_types_id: this.resident_types_id,
            contact: {
                twitter: this.twitter,
                facebook: this.facebook,
                email: this.email,
                phone: this.mobile
            }
        };
    }
}