import ticketApi from '../../api/tickets';
import Ticket from '../../models/ticket';
import User from '@/models/user';

const state = () => ({
    currentTicket: null,
    tickets: null,
    isSubmit: false,
    isDelete: false,
    ticketEntries: null,
    ticketEntriesTotal: 0,
    ticketEntriesTotalPage: 0,
    ticketOwners: null,
    ticketOwnersTotal: 0,
    ticketOwnersTotalPage: 0,
})

// getters
const getters = {
    currentTicket: (state) => {
        return state.currentTicket;
    },
    ticketList: (state) => {
        return state.tickets;
    },
    isSubmit: (state) => {
        return state.isSubmit;
    },
    isDelete: (state) => {
        return state.isDelete;
    },
    ticketEntries: (state) => {
        return state.ticketEntries;
    },
    ticketEntriesTotal: (state) => {
        return state.ticketEntriesTotal;
    },
    ticketEntriesTotalPage: (state) => {
        return state.ticketEntriesTotalPage;
    },
    ticketOwners: (state) => {
        return state.ticketOwners;
    },
    ticketOwnersTotal: (state) => {
        return state.ticketOwnersTotal;
    },
    ticketOwnersTotalPage: (state) => {
        return state.ticketOwnersTotalPage;
    }
}

// mutations
const mutations = {
    setCurrentTicket (state, ticket) {
        state.currentTicket = ticket;
    },
    updateTicketList (state, tickets) {
        state.tickets = tickets.map(t => new Ticket(t));
    },
    isSubmit (state, isSubmit) {
        state.isSubmit = isSubmit;
    },
    isDelete (state, isDelete) {
        state.isDelete = isDelete;
    },
    updateTicketEntries (state, data) {
        state.ticketEntries = data;
    },
    updateTicketEntriesTotal (state, data) {
        state.ticketEntriesTotal = data;
    },
    updateTicketEntriesTotalPage (state, data) {
        state.ticketEntriesTotalPage = data;
    },
    updateTicketOwners (state, data) {
        state.ticketOwners = data;
    },
    updateTicketOwnersTotal (state, data) {
        state.ticketOwnersTotal = data;
    },
    updateTicketOwnersTotalPage (state, data) {
        state.ticketOwnersTotalPage = data;
    }
}

// actions
const actions = {
    setCurrentTicket ({ commit }, ticket) {
        commit('setCurrentTicket', ticket);
    },
    fetchTickets ({ commit }, eventId) {
        commit('setLoading', true, { root: true });
        return new Promise((resolve, reject) => {
            ticketApi.getTicketList(eventId)
                .then(response => {
                    const tickets = response.data.data;
                    commit('updateTicketList', tickets);
                    resolve('success');
                })
                .catch(error => reject(error))
                .finally(() => commit('setLoading', false, { root: true }));
        });
    },
    fetchTicketEntries ({ commit }, thePayload) {
        commit('setLoading', true, { root: true });
        return new Promise((resolve, reject) => {
            ticketApi.getWhoEntries(thePayload.eventId, thePayload.perPage, thePayload.currentPage)
                .then(response => {
                    const tickets = [];
                    const responseData = response.data.data;
                    const totalTickets = response.data.pagination.total;
                    const totalPage = response.data.pagination.total_page;
                    responseData.forEach(element => {      
                        const ticket = {
                            id: element.id, 
                            quantity: element.quantity,
                            user: element.user ? new User(element.user): null,
                            confirmed_by: element.confirmed_by ? new User(element.confirmed_by) : null,
                            status: element.status,
                            created_at: element.created_at ? element.created_at : null,
                            updated_at: element.updated_at ? element.updated_at : null
                        };
                        tickets.push(ticket);
                    });
                    commit('ticket/updateTicketEntries', tickets, {root: true});
                    commit('ticket/updateTicketEntriesTotal', totalTickets, {root: true});
                    commit('ticket/updateTicketEntriesTotalPage', totalPage, {root: true});
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setLoading', false, { root: true }));
        });
    },
    fetchTicketOwners ({ commit }, thePayload) {
        commit('setLoading', true, { root: true });
        return new Promise((resolve, reject) => {
            ticketApi.getWhoBought(thePayload.eventId, thePayload.perPage, thePayload.currentPage)
                .then(response => {
                    const tickets = [];
                    const responseData = response.data.data;
                    const totalTickets = response.data.pagination.total;
                    const totalPage = response.data.pagination.total_page;
                    responseData.forEach(element => {  
                        const ticket = {
                            id: element.id, 
                            quantity: element.quantity,
                            user: element.user ? new User(element.user): null,
                            confirmed_by: element.confirmed_by ? element.confirmed_by : null,
                            created_at: element.created_at ? element.created_at : null,
                            updated_at: element.updated_at ? element.updated_at : null
                        };    
                        tickets.push(ticket);
                    });
                    commit('ticket/updateTicketOwners', tickets, {root: true});
                    commit('ticket/updateTicketOwnersTotal', totalTickets, {root: true});
                    commit('ticket/updateTicketOwnersTotalPage', totalPage, {root: true});
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setLoading', false, { root: true }));
        });
    },
    callSubmit ({ commit }) {
        commit('isSubmit', true);
    },
    callSubmitFinish ({ commit }) {
        commit('isSubmit', false);
    },
    callDelete ({ commit }) {
        commit('isDelete', true);
    },
    callDeleteFinish ({ commit }) {
        commit('isDelete', false);
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}