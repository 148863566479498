import users from '../../api/users';

const state = () => ({
    token: '',
})

// getters
const getters = {
    isAuth: () => {
        const token = window.localStorage.getItem('token')
        return !(token === null || token === undefined || token.trim() === '')
    }
}

// mutations
const mutations = {
    updateToken (state, { token }) {
        state.token = token;
    },
}

// actions
const actions = {
    /**
     * 
     * @param {*} credential is { username: String, type: String, password: String }
     */
    logIn ({ commit }, credential) {
        commit('updateToken', '');
        commit('setLoading', true, { root: true });
        return new Promise((resolve, reject) => {
            users.login(credential)
                .then(response => {
                    const token = response.data.data.token;
                    commit('updateToken', token);
                    localStorage.setItem('token', token);
                    // decode token
                    resolve(response)
                })
                .catch(error => reject(error))
                .finally(() => commit('setLoading', false, { root: true }));
        });
    },
    logOut ({ commit }) {
        commit('updateToken', '');
        localStorage.removeItem('token');
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}