<template>
  <!--begin::Row-->
  <div class="row">
    <div class="col-xl-2"></div>
    <div class="col-xl-7">
      <div class="form-group row align-items-center">
          <label class="col-form-label col-3 text-lg-right text-left">Ticket Title</label>
          <div class="col-9">
            <input class="form-control form-control-lg form-control-solid" type="text" v-model="form.ticketTitle" placeholder="enter your ticket title" />
          </div>
      </div>
      <div class="form-group row align-items-center">
          <label class="col-form-label col-3 text-lg-right text-left">Type</label>
          <div class="col-9">
            <el-radio v-model="form.type" size="small" label="available" border>Available</el-radio>
            <el-radio v-model="form.type" size="small" label="unavailable" border>UnAvailable</el-radio>
          </div>
      </div>
      <div class="separator separator-dashed my-10"></div>

      <div class="my-2">
        <div class="row">
          <label class="col-form-label col-3 text-lg-right text-left"></label>
          <div class="col-9">
            <h6 class="text-dark font-weight-bold mb-7">Sales Period:</h6>
          </div>
        </div>

        <!--begin::Group-->
        <div class="form-group row">
          <label class="col-form-label text-right col-lg-3 col-sm-12">Begin - End</label>
          <div class="col-lg-4 col-md-9 col-sm-12">
            <div class="input-group date">
              <vc-date-picker v-model="form.saleStart" mode="dateTime">
                <template v-slot="{ inputValue, inputEvents }">
                  <input type="text" class="form-control form-control-lg form-control-solid" readonly  placeholder="Start Date/Time" :value="inputValue" v-on="inputEvents"/>
                </template>
              </vc-date-picker>
            </div>
          </div>
          <div class="col-lg-4 col-md-9 col-sm-12">
            <div class="input-group date">
              <vc-date-picker v-model="form.saleEnd" mode="dateTime">
                <template v-slot="{ inputValue, inputEvents }">
                  <input type="text" class="form-control form-control-lg form-control-solid" readonly  placeholder="End Date/Time" :value="inputValue" v-on="inputEvents"/>
                </template>
              </vc-date-picker>
            </div>
          </div>
        </div>
        <!--end::Group-->

        <div class="row">
          <label class="col-form-label col-3 text-lg-right text-left"></label>
          <div class="col-9">
            <h6 class="text-dark font-weight-bold mb-7">Entry Period:</h6>
          </div>
        </div>

         <!--begin::Group-->
        <div class="form-group row">
          <label class="col-form-label text-right col-lg-3 col-sm-12">Begin - End</label>
          <div class="col-lg-4 col-md-9 col-sm-12">
            <div class="input-group date">
              <vc-date-picker v-model="form.validStart" mode="dateTime">
                <template v-slot="{ inputValue, inputEvents }">
                  <input type="text" class="form-control form-control-lg form-control-solid" readonly  placeholder="Start Date/Time" :value="inputValue" v-on="inputEvents"/>
                </template>
              </vc-date-picker>
            </div>
          </div>
          <div class="col-lg-4 col-md-9 col-sm-12">
            <div class="input-group date">
              <vc-date-picker v-model="form.validEnd" mode="dateTime">
                <template v-slot="{ inputValue, inputEvents }">
                  <input type="text" class="form-control form-control-lg form-control-solid" readonly  placeholder="End Date/Time" :value="inputValue" v-on="inputEvents"/>
                </template>
              </vc-date-picker>
            </div>
          </div>
        </div>
        <!--end::Group-->

        <div class="separator separator-dashed my-10"></div>
        <div class="row">
          <label class="col-form-label col-3 text-lg-right text-left"></label>
          <div class="col-9">
            <h6 class="text-dark font-weight-bold mb-7">About Attandance</h6>
          </div>
        </div>

        <!--begin::Group-->
        <div class="form-group row align-items-center">
          <label class="col-form-label col-3 text-lg-right text-left">Restriction Email</label>
          <div class="col-9">
            <input class="form-control form-control-lg form-control-solid" type="text" v-model="form.canAttendBy" placeholder="example: @google.com,@apple.com" />
            <span class="form-text text-muted">Only people who have these email domains can join this event</span>
          </div>
        </div>
        <!--end::Group-->

        <!-- <div class="form-group row mb-2">
          <label class="col-form-label col-3 text-lg-right text-left">Only Member</label>
          <div class="col-9">
            <span class="switch">
              <label>
                <input type="checkbox" checked="checked" v-model="form.onlyMember" name="select" />
                <span></span>
              </label>
            </span>
            <span class="form-text text-muted">Only member can join this event</span>
          </div>
        </div>
        
        <div class="separator separator-dashed my-10"></div> -->

        <!--begin::Group-->
        <div class="form-group row">
          <label class="col-form-label col-3 text-lg-right text-left">Who can confirm</label>
          <div class="col-9">
            <input class="form-control form-control-lg form-control-solid" type="text" v-model="form.canScanBy" placeholder="enter email eg: admin@domain.com" />
            <span class="form-text text-muted">Only these accounts can accept Ticket from this event</span>
          </div>
        </div>
        <!--end::Group-->

        <!--begin::Group-->
        <div class="form-group row">
          <label class="col-form-label col-3 text-lg-right text-left">Max Quantity</label>
          <div class="col-9">
            <input class="form-control form-control-lg form-control-solid" type="number" v-model="form.quantityTotal.value" placeholder="0" />
            <span class="form-text text-muted">Use -1 to unlimit ticket</span>
          </div>
        </div>
        <!--end::Group-->

        <!-- begin::Group-->
        <div class="form-group row">
          <label class="col-form-label col-3 text-lg-right text-left">Quantity Per Ticket</label>
          <div class="col-9">
            <input class="form-control form-control-lg form-control-solid" type="number" v-model="form.quantityPerOrder" placeholder="Enter number" />
            <span class="form-text text-muted">Number of quantity per a ticket</span>
          </div>
        </div>
        <!--end::Group -->

        <!-- begin::Group-->
        <div class="form-group row">
          <label class="col-form-label col-3 text-lg-right text-left">Limit Ticket can Buy</label>
          <div class="col-9">
            <input class="form-control form-control-lg form-control-solid" type="number" v-model="form.maximumCanBuy" placeholder="Enter number" />
            <span class="form-text text-muted">Number of maximum ticket can buy per customer</span>
          </div>
        </div>
        <!--end::Group -->

        <!-- begin::Group-->
        <div class="form-group row">
          <label class="col-form-label col-3 text-lg-right text-left">Customer use limit</label>
          <div class="col-9">
            <div class="row">
              <div class="col-auto">
                <el-switch class="pt-5 ml-1"
                  v-model="haveLimit"
                  >
                </el-switch>
              </div>
              <div class="col">
                <p class="text-muted mt-3" v-show="!haveLimit">No</p>
                <div class="input-group" v-show="haveLimit">
                  <input type="number" class="form-control" placeholder="number limit" v-model="limitPerDay.frequencyNumber">
                  <div class="input-group-append">
                    <span class="input-group-text" id="basic-addon2">Quantity Per Day</span>
                  </div>
                </div>
              </div>
              
              
            </div>
          </div>
        </div>
        <!--end::Group -->

        <!-- <div class="form-group row">
          <label class="col-form-label col-3 text-lg-right text-left">Price Type</label>
          <div class="col-9 radio-list">
              <label class="radio">
                  <input type="radio" name="radios1"/>
                  <span></span>
                  Per Quantity
              </label>
              <label class="radio">
                  <input type="radio" checked="checked" name="radios1"/>
                  <span></span>
                  Per Ticket
              </label>
              <span class="form-text text-muted">When price per ticket is selected, quantity in ticket will always maximun</span>
          </div>
        </div> -->

        <!--begin::Group-->
        <!-- <div class="form-group row">
          <label class="col-form-label col-3 text-lg-right text-left">Price</label>
          <div class="col-9">
            <input class="form-control form-control-lg form-control-solid" type="number" v-model="form.price.value" placeholder="0" />
            <span class="form-text text-muted">0 is Free!</span>
          </div>
        </div> -->
        <!--end::Group-->

        <!-- <div class="separator separator-dashed my-10"></div> -->

        <!--begin::Group-->
        <div class="form-group row">
          <label class="col-form-label col-3 text-lg-right text-left">Primary Language</label>
          <div class="col-9">
            <select class="form-control form-control-lg form-control-solid" v-model="form.language">
              <option>Select Language...</option>
              <option value="Thai">Thai</option>
              <option value="English">English</option>
            </select>
            <span class="form-text text-muted">It's optional</span>
          </div>
        </div>
        <!--end::Group-->

      </div>
    </div>
  </div>
  <!--end::Row-->
</template>

<script>
import { mapGetters } from 'vuex'
import Ticket from '../../models/ticket'
import ticketApi from '../../api/tickets'

export default {
  props: {
    modal: Object
  },
  data() {
    return {
      date: [
        [new Date(), new Date()]
      ],
      form: new Ticket(),
      haveLimit: false,
      limitPerDay: {
        frequencyType: 'day',
        frequencyNumber: 1
      }
    }
  },
  computed: {
    ...mapGetters({
      ticket: 'ticket/currentTicket',
      isSubmit: 'ticket/isSubmit',
      isDelete: 'ticket/isDelete'
    })
  },
  methods: {
    normalizeData(){
      if(this.haveLimit){
        this.form.conditions = [ { frequency_type: this.limitPerDay.frequencyType, frequency_number: this.limitPerDay.frequencyNumber} ];
      }else{
        this.form.conditions = [];
      }
    },
    async submit(){
      this.$store.dispatch('setLoading', true);
      try{
        this.normalizeData();
        const ticketId = this.form.id;
        const eventId = this.$route.params.id;
        if(ticketId){ // update
          await ticketApi.updateTicket(eventId, ticketId, this.form.toJSON());
        }else{ // create
          await ticketApi.createTicket(eventId, this.form.toJSON());
        }
        await this.$store.dispatch('ticket/fetchTickets', eventId);
        this.$notify({
          title: 'Successfully',
          message: 'Create new ticket succesfully!',
          type: 'success'
        });
        this.modal.hide();
      }catch(error){
        this.$alert(error.response ? error.response.data.message : error.message, 'Submit Fail'
        , { confirmButtonText: 'OK', type: 'error' } );
        console.log(error);
      }finally{
        this.$store.dispatch('setLoading', false);
        this.$store.dispatch('ticket/callSubmitFinish');
      }
    },
    async deleteTicket(){
      this.$store.dispatch('setLoading', true);
      try{
        const ticketId = this.form.id;
        const eventId = this.$route.params.id;
        await ticketApi.deleteTicket(eventId, ticketId);
        await this.$store.dispatch('ticket/fetchTickets', eventId);
        this.$notify({
          title: 'Successfully',
          message: 'Delete ticket succesfully!',
          type: 'success'
        });
        this.modal.hide();
      }catch(error){
        this.$alert(error.response ? error.response.data.message : error.message, 'Delete Fail'
        , { confirmButtonText: 'OK', type: 'error' } );
      }finally{
        this.$store.dispatch('setLoading', false);
        this.$store.dispatch('ticket/callDeleteFinish');
      }
    },
  },
  watch:{
    'ticket.id'(value){
      this.form = value ? this.ticket : new Ticket();
      if(this.form.conditions?.length > 0){
        const limitPerDay = this.form.conditions.find(c => 'day' == c.frequency_type);
        if(limitPerDay){
          this.haveLimit = true;
          this.limitPerDay.frequencyType = limitPerDay.frequency_type;
          this.limitPerDay.frequencyNumber = limitPerDay.frequency_number;
        }
      }else{
        this.haveLimit = false;
        this.limitPerDay = { frequencyType: 'day', frequencyNumber: 1};
      }
    },
    isSubmit(value){
      if(value){ // call submit
        this.submit();
      }
    },
    isDelete(value){
      if(value){
        this.deleteTicket();
      }
    }
  }
}
</script>