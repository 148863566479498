<template>
  <!--begin::Subheader-->
  <div class="subheader py-2 py-lg-4 subheader-solid" id="kt_subheader">
    <div class="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
      <!--begin::Details-->
      <div class="d-flex align-items-center flex-wrap mr-2">
        <!--begin::Title-->
        <h5 class="text-dark font-weight-bold mt-2 mb-2 mr-5">Events</h5>
        <!--end::Title-->
        <!--begin::Separator-->
        <div class="subheader-separator subheader-separator-ver mt-2 mb-2 mr-5 bg-gray-200"></div>
        <!--end::Separator-->
        <!--begin::Search Form-->
        <div class="d-flex align-items-center" id="kt_subheader_search">
          <span class="text-dark-50 font-weight-bold" id="kt_subheader_total">{{ totalNumber }} Total</span>
        </div>
        <!--end::Search Form-->
      </div>
      <!--end::Details-->
      <!--begin::Toolbar-->
      <div class="d-flex align-items-center">
        <a @click="goUpdateTicket" class="btn btn-light-primary font-weight-bold ml-2">Edit Tickets</a>
        <a @click="goUpdateEvent" class="btn btn-light-primary font-weight-bold ml-2">Edit Event</a>
        <a @click="deleteEvent" class="btn btn-light-danger font-weight-bold ml-2">Delete</a>
      </div>
      <!--end::Toolbar-->
    </div>
  </div>
  <!--end::Subheader-->
</template>

<script>
import eventApi from '../../api/events'

export default {
  props: {
    totalNumber: {
      type: Number,
      default: 10
    }
  },
  mounted(){
    // const id = this.$route.params.id;
  },
  methods: {
    async deleteEvent(){
       this.$confirm('This will permanently delete the event. Continue?', 'Warning', {
        confirmButtonText: 'Delete Event',
        cancelButtonText: 'Cancel',
        type: 'warning'
        }).then(async () => {
                const id = this.$route.params.id;
                this.$store.dispatch('setLoading', true);
                try{
                  await eventApi.deleteEvent(id);
                  this.$router.replace({ name: 'EventList' });
                }catch(error){
                  console.log(error);
                }finally{this.$store.dispatch('setLoading', false)}
        })
        .catch(() => {
          console.log('cancel delete');
        });
      
    },
    goUpdateTicket(){
      this.$store.dispatch('event/updateShowTicketTab', true);
      this.$router.push({ name: 'EventEdit'});
    },
    goUpdateEvent(){
      this.$store.dispatch('event/updateShowTicketTab', false);
      this.$router.push({ name: 'EventEdit'});
    }
  }
}
</script>