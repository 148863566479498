import Login from '@/views/auth/Login'
import EventCreate from '@/views/event/Create'
import EventEdit from '@/views/event/Edit'
import EventList from '@/views/event/List'
import EventView from '@/views/event/View'
import QRScan from '@/views/qr/scan'
import TicketTypeCreate from '@/views/ticketType/Create'
import TicketTypeEdit from '@/views/ticketType/Edit'
import TicketTypeList from '@/views/ticketType/List'
import UserAccountInfo from '@/views/user/AccountInfo'
import UserCreate from '@/views/user/Create'
import UserDetail from '@/views/user/Detail'
import UserList from '@/views/user/List'
import UserChangePassword from '@/views/user/Password'
import UserPersonal from '@/views/user/Personal'
import axios from 'axios'
import jsonwebtoken from 'jsonwebtoken'
import VueRouter from 'vue-router'


const routes = [
  {
    path: '/',
    name: 'Home',
    component: EventList
  },
  {
    path: '/users',
    name: 'UserList',
    component: UserList
  },
  {
    path: '/user/detail',
    name: 'UserDetail',
    component: UserDetail
  },
  {
    path: '/user/detail/personal',
    name: 'UserPersonal',
    component: UserPersonal
  },
  {
    path: '/user/detail/account',
    name: 'UserAccountInfo',
    component: UserAccountInfo
  },
  {
    path: '/user/detail/password',
    name: 'UserChangePassword',
    component: UserChangePassword
  },
  {
    path: '/user/create',
    name: 'UserCreate',
    component: UserCreate
  },
  {
    path: '/events',
    name: 'EventList',
    component: EventList
  },
  {
    path: '/event/create',
    name: 'EventCreate',
    component: EventCreate
  },
  {
    path: '/event/:id',
    name: 'EventView',
    component: EventView
  },
  {
    path: '/event/:id/edit',
    name: 'EventEdit',
    component: EventEdit
  },
  {
    path: '/ticketTypes',
    name: 'TicketTypeList',
    component: TicketTypeList
  },
  {
    path: '/ticketType/create',
    name: 'TicketTypeCreate',
    component: TicketTypeCreate
  },
  {
    path: '/ticketType/edit',
    name: 'TicketTypeEdit',
    component: TicketTypeEdit
  },
  {
    path: '/qr/:id',
    name: 'QR',
    component: QRScan
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  }
]

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior (to) {
    if (to.name === 'EventEdit') {
      return { x:0, y:0 }
    }
  }
})


router.beforeEach((to, from, next) => {
  const token = window.localStorage.getItem('token')
  const hasToken = (token !== undefined && token !== null && token.trim() !== '')

  if (hasToken) { // handle has token case
    const decoded = jsonwebtoken.decode(token);
    const isExpire = (new Date(decoded.exp * 1000) - new Date()) < 0; // handle expire
    // console.log((new Date(decoded.exp * 1000) - new Date()));
    if (isExpire) { // handle token expire;
      localStorage.removeItem('token');
      sessionStorage.removeItem('token');
      window.location = '/';
    } else {
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    }
    if ('Login' === to.name) {
      return next({ name: 'Home' });
    }
  } else { // handle doesn't has token case
    if (to.name !== 'Login') {
      sessionStorage.setItem('backUrl', window.location.href)
      return next({ name: 'Login' })
    }
  }

  next() // ignore when don't have any case from above
})

export default router
