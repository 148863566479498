<template>

	<div class="content d-flex flex-column flex-column-fluid" id="kt_content">
		<SubHeader/>
		<div class="d-flex flex-column-fluid">
			<!--begin::Container-->
			<div class="container">
				<!--begin::Profile Overview-->
				<div class="d-flex flex-row">
					<SideMenu/>
					<!--begin::Content-->
					<div class="flex-row-fluid ml-lg-8">
						<!--begin::Advance Table: Widget 7-->
						<div class="card card-custom gutter-b">
							<!--begin::Header-->
							<div class="card-header border-0 pt-5">
								<h3 class="card-title align-items-start flex-column">
									<span class="card-label font-weight-bolder text-dark">My Tickets</span>
									<span class="text-muted mt-3 font-weight-bold font-size-sm">More than {{totalTickets}} tickets</span>
								</h3>
							</div>
							<!--end::Header-->
							<!--begin::Body-->
							<div class="card-body pt-2 pb-0 mt-n3">
								<div class="tab-content mt-5" id="myTabTables12">
																			<!--begin::Table-->
										<div class="table-responsive">
											<table class="table table-borderless table-vertical-center">
												<thead>
													<tr>
														<th class="p-0 w-300px"></th>
														<th class="p-0 min-w-200px"></th>
														<th class="p-0 min-w-120px"></th>
													</tr>
												</thead>
												<tbody>
													<tr v-for="(ticket, index) in tickets" :key="index">
														<td class="p-0 py-4" v-if="ticket.logo">
															<div class="symbol symbol-50 symbol-light mr-5">
																<span class="symbol-label">
																	<img :src="`/assets/media/svg/misc/${ticket.logo}`" class="h-50 align-self-center" alt="" />
																</span>
															</div>
														</td>
														<td class="pl-0">
															<a href="#" class="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg" data-toggle="modal" data-target="#exampleModalCenter" @click="openTicketModal(ticket)">{{ ticket.ticketTitle }}</a>
														</td>
														<td class="text-right" v-if="ticket.buytime">
															<span class="text-muted font-weight-bold">{{ ticket.buytime.utc }}</span>
														</td>
														<td class="text-right" v-if="ticket.status">
															<span :class="`label label-lg label-light-${isValid(ticket.status)? 'success' : 'danger'} label-inline`">{{ ticket.status }}</span>
														</td>
														<td class="pr-0 text-right" style="display:none;">
															<a href="#" class="btn btn-icon btn-light btn-hover-primary btn-sm" data-toggle="modal" data-target="#exampleModalCenter">
																<span class="svg-icon svg-icon-md svg-icon-primary">
																	<!--begin::Svg Icon | path:assets/media/svg/icons/General/Settings-1.svg-->
																	<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
																		<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
																			<rect x="0" y="0" width="24" height="24" />
																			<path d="M7,3 L17,3 C19.209139,3 21,4.790861 21,7 C21,9.209139 19.209139,11 17,11 L7,11 C4.790861,11 3,9.209139 3,7 C3,4.790861 4.790861,3 7,3 Z M7,9 C8.1045695,9 9,8.1045695 9,7 C9,5.8954305 8.1045695,5 7,5 C5.8954305,5 5,5.8954305 5,7 C5,8.1045695 5.8954305,9 7,9 Z" fill="#000000" />
																			<path d="M7,13 L17,13 C19.209139,13 21,14.790861 21,17 C21,19.209139 19.209139,21 17,21 L7,21 C4.790861,21 3,19.209139 3,17 C3,14.790861 4.790861,13 7,13 Z M17,19 C18.1045695,19 19,18.1045695 19,17 C19,15.8954305 18.1045695,15 17,15 C15.8954305,15 15,15.8954305 15,17 C15,18.1045695 15.8954305,19 17,19 Z" fill="#000000" opacity="0.3" />
																		</g>
																	</svg>
																	<!--end::Svg Icon-->
																</span>
															</a>
														</td>
													</tr>

												</tbody>
											</table>
										</div>
										<!--end::Table-->
								</div>
								
							</div>
							<div class="card-footer d-flex justify-content-between">
								<!--begin::Pagination-->
								<div class="d-flex justify-content-between align-items-center flex-wrap">
										<div class="d-flex flex-wrap py-2 mr-3">
												<a @click="currentPage = 1" class="btn btn-icon btn-sm btn-light-primary mr-2 my-1">
												<i class="ki ki-bold-double-arrow-back icon-xs"></i>
												</a>
												<a @click="currentPage = currentPage == 1 ? currentPage : parseInt(currentPage) - 1" class="btn btn-icon btn-sm btn-light-primary mr-2 my-1">
												<i class="ki ki-bold-arrow-back icon-xs"></i>
												</a>
												<div v-for="(item, index) in totalPage" :key="item">
												<a 
												:class='{ "active": index + 1 == currentPage}' 
												@click="currentPage = index + 1"
												class="btn btn-icon btn-sm border-0 btn-hover-primary mr-2 my-1">
												{{ index + 1 }}
												</a>
												</div>
												
												<a @click="currentPage = currentPage == totalPage ? currentPage : parseInt(currentPage) + 1" class="btn btn-icon btn-sm btn-light-primary mr-2 my-1">
												<i class="ki ki-bold-arrow-next icon-xs"></i>
												</a>
												<a @click="currentPage = totalPage" class="btn btn-icon btn-sm btn-light-primary mr-2 my-1">
												<i class="ki ki-bold-double-arrow-next icon-xs"></i>
												</a>
										</div>
										<div class="d-flex align-items-center">
											<select v-model="perPage" class="form-control form-control-sm text-primary font-weight-bold mr-4 border-0 bg-light-primary" style="width: 75px;">
												<option value="10">10</option>
												<option value="20">20</option>
												<option value="30">30</option>
												<option value="50">50</option>
												<option value="100">100</option>
											</select>
											<span class="text-muted">Displaying {{ perPage > totalTickets ? totalTickets : perPage }} of {{ totalTickets }} records</span>
										</div>
								</div>
								<!--end:: Pagination-->
							</div>
							<!--end::Body-->
						</div>
						<!--end::Advance Table Widget 7-->
					</div>
				</div>
			</div>
			<!--end::Container-->
		</div>
		<!-- Modal-->
		<div class="modal fade" id="exampleModalCenter" data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="staticBackdrop" aria-hidden="true" v-if="modalTicket">
				<div class="modal-dialog modal-dialog-centered" role="document">
					<div class="modal-content">
						<div class="modal-body">
							<!--begin::Stats Widget 1-->
							<div class="card card-custom bgi-no-repeat card-border card-stretch gutter-b ribbon ribbon-top" style="background-position: right top; background-size: 30% auto; background-image: url(/assets/media/svg/shapes/abstract-4.svg)">
								<div :class="`ribbon-target bg-success`" style="top: -2px; right: 20px;">Available</div>
								<!--begin::Body-->
								<div class="card-body">
									<h3>{{modalTicket.ticketTitle}}</h3>
									<h6 class="text-muted">{{modalTicket.location.detail}}</h6>
									<div class="font-weight-bold text-success mt-9 mb-5" v-if="modalTicket.buytime">{{ modalTicket.buytime.utc }}</div>
									<!-- <p class="text-dark-75 font-weight-bolder font-size-h5 m-0" >
										Remaining 1/5
									</p> -->
								</div>
								<!--end::Body-->
							</div>
							<!--end::Stats Widget 1-->
            </div>
						<div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
            </div>
					</div>
				</div>
		</div>
	</div>
</template>

<script>
import SideMenu from './SideMenu'
import SubHeader from './SubHeader3'
import {mapGetters} from 'vuex';
export default {
	data() {
		return {
			currentPage: 1,
			perPage: 10,
			modalTicket: null
		}
	},
	methods: {
		isValid(status) {
			return status === 'available'
		},
		openTicketModal(ticket) {
			this.modalTicket = ticket;
		}
	},
	mounted() {
		if(this.isAuth){
			const params = {perPage: this.perPage, currentPage: this.currentPage};
			this.$store.dispatch('user/refreshMyTickets', params);
		}
	},
	computed: {
		...mapGetters({
			isAuth: 'auth/isAuth',
			tickets: 'user/myTickets',
			totalTickets: 'user/totalTickets',
			totalPage: 'user/totalPage'
		})
	},
	watch: {
		currentPage: function (val) {
			console.log(val);
			const params = {perPage: this.perPage, currentPage: val};
			this.$store.dispatch('user/refreshMyTickets', params);
		}
	},
	components: {
		SideMenu, SubHeader
	}
}
</script>