<template>
    <div class="container main body-scan">
        <div v-if="!showHistory" class="row text-center">
            <div class="w-100 header1 my-5 p-1">
                <img src="/assets/images/scan.jpg" alt="SCAN" id="img-header"/>
            </div>
            <div class="w-100 my-4">
                <h4>
                    {{ eventName }}
                </h4>
            </div>
            <div class="w-100 mx-5 attendance">
                <h6 class="head-border">{{ ticketName }} Ticket</h6>
                <p class="m-0"><i class="el-icon-user mr-2"></i>Name: {{ attendanceName }}</p>
                <p class="m-0 pb-5"><i class="el-icon-message mr-2"></i>Username: {{ attendanceEmail }}</p>
                <h5 class="m-0 pb-5 text-warning">{{ attendanceType }}</h5>
            </div>
            <div class="w-100">
                <div v-if="limitScan">
                    <p class="text-danger pt-5">Your have limit scan ticket</p>
                </div>
                <div v-else>
                    <p class="pt-5">Quantity</p>
                    <select v-model="numberUsingTickets" class="mb-5" style="display: block; margin-left:auto; margin-right:auto;">
                        <option :value="0">0</option>
                        <option v-for="i in limitQuantity" :key="i"  :value="i">{{ i }}</option>
                    </select>
                    <button type="button" @click="submit" class="btn btn-primary btn-sm">Submit</button>
                </div>
            </div>
            <div v-show="histories.length > 0" class="mx-0 w-100">
                <h5 class="mt-3 ml-4 text-left">Scan History</h5>
                <div class="table-responsive">
                    <table class="table body-scan table-bordered table-sm">
                        <thead>
                            <tr>
                            <th scope="col">#</th>
                            <th scope="col">Time</th>
                            <th scope="col">Verify By</th>
                            <th scope="col">Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(history, index) in histories" :key="index">
                            <th scope="row">{{index + 1}}</th>
                            <td>{{ formatTime(history.time) }}</td>
                            <td>{{ history.verifyBy}}</td>
                            <td>{{ history.status }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
         <div v-else class="w-100 pt-5">
            <div class="card mx-0">
                <div class="card-body">
                    <h5 class="card-title my-0">Scan History</h5>
                    <div class="table-responsive">
                    <table class="table">
                        <thead>
                            <tr>
                            <th scope="col">#</th>
                            <th scope="col">Time</th>
                            <th scope="col">Verify By</th>
                            <th scope="col">Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(history, index) in histories" :key="index">
                            <th scope="row">{{index + 1}}</th>
                            <td>{{ formatTime(history.time) }}</td>
                            <td>{{ history.verifyBy}}</td>
                            <td>{{ history.status }}</td>
                            </tr>
                        </tbody>
                    </table>
                    </div>
                </div>
            </div>
        </div>
        <Alert :isCenter="true" v-if="errorMessage != ''" @close="$router.go()" :title="'Error'">
            {{ errorMessage }}
        </Alert>
        <DialogSuccess/>
    </div>
</template>

<script>
import Alert from '../../components/Alert';
import DialogSuccess from '../../components/DialogSuccess';
import ticketApi from '../../api/tickets';
import moment from 'moment';

export default {
    data(){
        return{
            numberUsingTickets: 1,
            errorMessage: '',
            showHistory: false,
            limitScan: false,
            ticketId: '',
            myTicketId: '',
            qrToken: '',
            ticketName: 'ticket name',
            eventName: 'event name',
            attendanceName: 'mikaru kinji',
            attendanceEmail: 'admin@gmail',
            attendanceType: 'VIP',
            histories: [],
            limitQuantity: 0
        }
    },
    methods: {
        async submit(){
            this.histories = [];
            if(0 == this.numberUsingTickets){
                this.errorMessage = 'Please choose number using tickets';
                return;
            }else{
                this.$store.dispatch('setLoading', true);
                try{
                    const response = await ticketApi.verifyTicket(this.ticketId, this.myTicketId, this.qrToken, this.numberUsingTickets);
                    this.histories = response.data.data.entry_logs.map(e => { return {
                        time: new Date(e.created_at.timestamp * 1000),
                        verifyBy: e.confirmed_by.username,
                        status: e.status.value
                    }});
                    console.log(response);
                    this.showHistory = true;
                    this.$modal.show('success');
                    await this.timeout(1700);
                    this.$modal.hide('success');
                }catch(error){
                    console.log(error);
                    this.errorMessage = error.response?.data?.message || error.message;
                }finally{
                    this.$store.dispatch('setLoading', false);
                }
            }
        },
        timeout(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
        },
        formatTime(date){
            return moment(date).format('D MMM YY, HH:mm:ss')
        },
        async refreshDataPage(){
            this.$store.dispatch('setLoading', true);
            this.myTicketId = this.$route.params.id;
            this.ticketId = this.$route.query.ticketId;
            this.qrToken = this.$route.query.token;
            try{
                const response = await ticketApi.showTicketBeforeAllowAccess(this.ticketId, this.myTicketId);
                const data = response.data.data;
                const ticket = data.ticket;
                const event = data.event;
                const user = data.bought_by;
                this.ticketName = ticket.title;
                this.eventName = event.title;
                this.attendanceName = user.first_name + ' ' + user.last_name;
                this.attendanceEmail = user.username;
                this.attendanceType = 'User'
                this.limitQuantity = data.quantity_remaning;
                this.histories = response.data.data.entry_logs.map(e => { return {
                            time: new Date(e.created_at.timestamp * 1000),
                            verifyBy: e.confirmed_by.username,
                            status: e.status.value
                        }});
            }catch(error){
                this.errorMessage = error.response?.data?.message || error.message;
            }finally{
                this.$store.dispatch('setLoading', false);
            }
        }
    },
    async mounted(){
        this.refreshDataPage();
    },
    components: {
        Alert,
        DialogSuccess
    }
}
</script>

<style scoped>
    .main{
        height: 100vh;
    }
    .header1{
        height: 150px;
    }
    #img-header{
        height: 150px;
        width: 100%;
        border-radius: 5px;
        object-fit: cover;
    }
    .attendance{
        border:1px solid #999999;
    }
    .head-border{
        margin-top:-10px;
        margin-left:auto;
        margin-right: auto;
        width: 135px;
        background-image: url("/assets/images/paper.webp");
    }
    .body-scan {
        background-color: #f7f7f7;
    }
</style>