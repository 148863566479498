import Vue from 'vue'
import Vuex from 'vuex'
import auth from './modules/auth'
import event from './modules/event'
import ticket from './modules/ticket'
import user from './modules/user'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        auth,
        user,
        event,
        ticket
    },

    // This is root store ---------------
    state: {
        loading: false
    },
    getters: {
        isLoading: (state) => {
            return state.loading;
        }
    },
    mutations: {
        setLoading (state, value) {
            state.loading = value;
        }
    },
    actions: {
        setLoading: ({ commit }, value) => {
            commit('setLoading', value);
        }
    }
})