import eventApi from '../../api/events';

const state = () => ({
    showTicketTab: false,
    categories: [], // { id: String, name: String }
    statusList: [], // [ String ]
    residentTypes: [],
    timezones: [{
        "id": "601d182e9ad72d00333d6982",
        "name": "Asia/Bangkok"
    }],
    default: {},
    totalEvents: 0,
})

// getters
const getters = {
    isShowTicketTab: (state) => {
        return state.showTicketTab;
    },
    categories: (state) => {
        return state.categories;
    },
    statusList: (state) => {
        return state.statusList;
    },
    timezones: (state) => {
        return state.timezones;
    },
    totalEvents: (state) => {
        return state.totalEvents;
    },
    residentTypes: (state) => {
        return state.residentTypes;
    },
    default: (state) => {
        return state.default;
    }
}

// mutations
const mutations = {
    updateShowTicketTab (state, isShow) {
        state.showTicketTab = isShow;
    },
    updateEventInfo (state, data) {
        state.categories = data.categories;
        state.statusList = data.status;
        state.timezones = data.timezones;
        state.residentTypes = data.restriction_types;
        state.default = data.default;
    },
    updateTotalEvents (state, data) {
        state.totalEvents = data;
    }
}

// actions
const actions = {
    updateShowTicketTab ({ commit }, isShow) {
        commit('updateShowTicketTab', isShow);
    },
    fetchEventInfo ({ commit }) {
        commit('setLoading', true, { root: true });
        return new Promise((resolve, reject) => {
            eventApi.getCreateInfo()
                .then(response => {
                    const data = response.data.data;
                    commit('updateEventInfo', data);
                    resolve('success');
                })
                .catch(error => reject(error))
                .finally(() => commit('setLoading', false, { root: true }));
        });
    },
    updateTotalEvents ({ commit }, totalEvents) {
        commit('updateTotalEvents', totalEvents);
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}