<template>
  <!--begin::Subheader-->
  <div class="subheader py-2 py-lg-4 subheader-solid" id="kt_subheader">
    <div class="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
      <!--begin::Details-->
      <div class="d-flex align-items-center flex-wrap mr-2">
        <!--begin::Title-->
        <h5 class="text-dark font-weight-bold mt-2 mb-2 mr-5">New Event</h5>
        <!--end::Title-->
        <!--begin::Separator-->
        <div class="subheader-separator subheader-separator-ver mt-2 mb-2 mr-5 bg-gray-200"></div>
        <!--end::Separator-->
        <!--begin::Search Form-->
        <div class="d-flex align-items-center" id="kt_subheader_search">
          <span class="text-dark-50 font-weight-bold" id="kt_subheader_total">Enter Event details and submit</span>
        </div>
        <!--end::Search Form-->
      </div>
      <!--end::Details-->
      <!--begin::Toolbar-->
      <div class="d-flex align-items-center">
        <!--begin::Button-->
        <router-link :to="{name:'EventList'}" class="btn btn-default font-weight-bold btn-sm px-3 font-size-base">Back</router-link>
        <!--end::Button-->
        <!--begin::Dropdown-->
        <div class="btn-group ml-2">
          <button @click="createEvent" type="button" class="btn btn-primary font-weight-bold btn-sm px-3 font-size-base">Create Event</button>
        </div>
        <!--end::Dropdown-->
      </div>
      <!--end::Toolbar-->
    </div>
  </div>
  <!--end::Subheader-->
</template>

<script>
export default {
  methods: {
    createEvent(){
      this.$emit('submit');
    }
  }
}
</script>