<template>
<div class="tab-pane px-7" :class="{ 'active': showTicketTab }" id="kt_user_edit_tab_2" role="tabpanel">
  <div class="row">
    <!-- begin list ticket -->
    <div class="col-xl-6"  v-for="ticket in tickets" :key="ticket.id">
      <div class="card card-custom bgi-no-repeat card-border card-stretch gutter-b ribbon ribbon-top" style="background-position: right top; background-size: 30% auto; background-image: url(/assets/media/svg/shapes/abstract-4.svg)">
        <div class="ribbon-target" :class="{ 'bg-secondary': ticket.type == 'unavailable' || ticket.type == 'hide' }" style="top: -2px; right: 20px;">{{ ticket.type }}</div>
          <div class="card-body">
            <a @click="openFormTicket(ticket)" style="cursor:pointer" class="card-title font-weight-bold text-muted text-hover-primary font-size-h5">{{ ticket.ticketTitle }}</a>
            <div class="font-weight-bold text-success mt-5 mb-1">Sale Period: 
              <font class="font-weight-normal">
                {{ formatTime(ticket.saleStart) }} - {{ formatTime(ticket.saleEnd) }}
              </font>
            </div>
            <div class="font-weight-bold text-success mt-0 mb-5">Entry Period: 
              <font class="font-weight-normal">
                {{ formatTime(ticket.validStart) }} - {{ formatTime(ticket.validEnd) }}
              </font>
            </div>
            <p class="text-dark-75 font-weight-bolder font-size-h5 m-0">
              Max Quantity: {{ ticket.quantityPerOrder.value }}
            </p>
            <div class="font-weight-bold text-muted font-size-sm mt-4">
              <span class="text-dark-75 font-size-h2 font-weight-bolder mr-2">{{ ticket.issued }}/{{ ticket.quantityTotal.value }}</span>Issued</div>
              <div class="progress progress-xs mt-7 bg-success-o-60">
                <div class="progress-bar bg-success" role="progressbar" :style="`width: ${ticket.issued / ticket.quantityTotal.value * 100}%;`" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
            </div>
          </div>
      </div>
    </div>
  </div>
    
  <!--End::Row-->
  <div class="row">
    <div class="col-xl-6">
      <!--begin::Engage Widget 1-->
      <div class="card card-custom card-stretch gutter-b">
        <div class="card-body d-flex p-0">
          <div class="flex-grow-1 p-8 card-rounded bgi-no-repeat d-flex align-items-center" style="background-color: #FFF4DE; background-position: left bottom; background-size: auto 100%; background-image: url(/assets/media/svg/humans/custom-2.svg)">
            <div class="row">
              <div class="col-12 col-xl-5"></div>
              <div class="col-12 col-xl-7">
                <h4 class="text-danger font-weight-bolder">Design your Ticket</h4>
                <p class="text-dark-50 my-5 font-size-xl font-weight-bold">You can design your ticket based on your event to separate user type or group</p>
                
                <a @click="openFormTicket(null)" class="btn btn-danger font-weight-bold py-2 px-6">Create a new Ticket</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--end::Engage Widget 1-->
    </div>
  </div>

    <!-- Modal-->
    <div class="modal fade" id="exampleModalLong" data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="staticBackdrop" aria-hidden="true">
        <div class="modal-dialog modal-xl" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Design your Ticket</h5>
                    <button @click="closeFormTicket" type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <i aria-hidden="true" class="ki ki-close"></i>
                    </button>
                </div>
                <div class="modal-body">
                    <TicketTypeCreate :modal="myModal"/>
                </div>
                <div class="modal-footer">
                    <button @click="closeFormTicket" type="button" class="btn btn-light-primary font-weight-bold" data-dismiss="modal">Close</button>
                    <button v-show="canDeleteTicket" @click="confirmDeleteTicket" type="button" class="btn btn-light-danger font-weight-bold">Delete</button>
                    <button type="button" @click="$store.dispatch('ticket/callSubmit')" class="btn btn-primary font-weight-bold">Save changes</button>
                </div>
            </div>
        </div>
    </div>
  </div>  
</template>

<script>
import TicketTypeCreate from '@/views/ticketType/Create'
import { mapGetters } from 'vuex'
import moment from 'moment'
import { Modal } from 'bootstrap';
// model
// import Ticket from '../../models/ticket'

export default {
  data(){
    return {
      myModal: null,
      canDeleteTicket: false
    }
  },
  components: {
    TicketTypeCreate
  },
  methods: {
    formatTime(date){
      return date ? moment(date).format('LLL') : 'forever';
    },
    openFormTicket(ticket){
      if(ticket == null){
        this.canDeleteTicket = false;
        this.$store.dispatch('ticket/setCurrentTicket', null);
      }else{
        this.canDeleteTicket = true;
        this.$store.dispatch('ticket/setCurrentTicket', Object.assign(Object.create(Object.getPrototypeOf(ticket)), ticket));
      }
      this.myModal.show();
    },
    closeFormTicket(){
      this.$store.dispatch('ticket/setCurrentTicket', null)
      this.myModal.hide();
    },
    confirmDeleteTicket(){
      this.$confirm('This will permanently delete the ticket. Continue?', 'Warning', {
        confirmButtonText: 'Delete Ticket',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(async () => {
        this.$store.dispatch('ticket/callDelete');
      })
      .catch(() => {
        console.log('cancel');
      })
    },
    async fetchTicket(){
      this.$store.dispatch('setLoading', true);
      try{
        this.$store.dispatch('ticket/fetchTickets', this.$route.params.id);// refresh ticket
      }catch(error){
        console.log(error);
      }finally{this.$store.dispatch('setLoading', false)}
    }
  },
  computed: {
    ...mapGetters({
      showTicketTab: 'event/isShowTicketTab',
      currentTicket: 'ticket/currentTicket',
      tickets: 'ticket/ticketList',
    })
  },
  async mounted(){
    this.myModal = new Modal(document.getElementById('exampleModalLong'), {
        keyboard: false,
        backdrop: 'static'
      });
    await this.fetchTicket();
  }
}
</script>