import axios from 'axios'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import VCalendar from 'v-calendar'
import Vue from 'vue'
import VModal from 'vue-js-modal'
import VueRouter from 'vue-router'
import Vuex from 'vuex'
import App from './App.vue'
import router from './router'
import store from './store'

axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;
axios.defaults.headers.common['api-key'] = process.env.VUE_APP_API_KEY;

Vue.use(ElementUI)
Vue.use(Vuex)
Vue.use(VCalendar, {
  componentPrefix: 'vc',  // Use <vc-calendar /> instead of <v-calendar />
});
Vue.use(VueRouter)
Vue.use(VModal, { componentName: 'modal' })
Vue.config.productionTip = false

Vue.filter('checkNaN', function(val) {
  return isNaN(val) ? 0 : val;
})

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
