<template>
  <!--begin::Main-->
  <div class="d-flex flex-column flex-root" style="position: absolute;top:0;left:0;right:0;bottom:0;">
    <!--begin::Login-->
    <div class="login login-4 login-signin-on d-flex flex-row-fluid">
      <div class="d-flex flex-center flex-row-fluid bgi-size-cover bgi-position-top bgi-no-repeat" style="background-image: url('assets/media/bg/bg-3.jpg');">
        <div class="login-form text-center p-7 position-relative overflow-hidden">
          <!--begin::Login Header-->
          <div class="d-flex flex-center mb-15">
          </div>
          <!--end::Login Header-->
          <!--begin::Login Sign in form-->
          <div class="login-signin">
            <div class="mb-20">
              <h3>Sign In To Admin</h3>
              <div class="text-muted font-weight-bold">Enter your details to login to your account:</div>
            </div>
            <form @submit.prevent="submitForm" class="form">
              <div class="form-group mb-5">
                <input class="form-control h-auto form-control-solid py-4 px-8" type="text" placeholder="Email" name="username" autocomplete="off" v-model="username" data-cy="username"/>
              </div>
              <div class="form-group mb-5">
                <input class="form-control h-auto form-control-solid py-4 px-8" type="password" placeholder="Password" name="password" v-model="password" data-cy="password"/>
              </div>
              <div class="form-group d-flex flex-wrap justify-content-between align-items-center">
                <div class="checkbox-inline">
                  <label class="checkbox m-0 text-muted">
                  <input type="checkbox" name="remember" />
                  <span></span>Remember me</label>
                </div>
                <a href="" class="text-muted text-hover-primary">Forget Password ?</a>
              </div>
              <button class="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4" data-cy="signin-button">Sign In</button>
            </form>
          </div>
          <!--end::Login Sign in form-->
        </div>
      </div>
    </div>
    <!--end::Login-->
    <alert v-if="errorMessage != ''" @close="errorMessage = ''" :title="'Error'">
      {{ errorMessage }}
    </alert>
  </div>
  <!--end::Main-->
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Alert from '../../components/Alert'

export default {
  data() {
    return {
      errorMessage: '',
      username: '',
      password: ''
    }
  },
  computed: {
    ...mapGetters('auth', {
      isAuth: 'isAuth'
    })
  },
  methods: {
    ...mapActions('auth', {
      logIn: 'logIn'
    }),

    async submitForm() {
      this.errorMessage = '';
      try {
        await this.logIn({ username: this.username, type: 'email', password: this.password});
        let backUrl = sessionStorage.getItem('backUrl');
        document.location = backUrl;
      } catch (error) {
        this.errorMessage = error.response? error.response.data.message : error.message;
      } 
    }
  },
  components: {
    Alert
  }
}
</script>