export default class Ticket {
    constructor(json) {
        /**
         * TO-DO remove after just mock up
         */
        if (json) {
            this.id = json.id;
            this.saleStart = json.sales_period.start ? new Date(json.sales_period.start) : null;
            this.saleEnd = json.sales_period.end ? new Date(json.sales_period.end) : null;
            this.validStart = json.entry_period[0] ? new Date(json.entry_period[0].start) : null;
            this.validEnd = json.entry_period[0] ? new Date(json.entry_period[0].end) : null;
            this.quantityTotal = { value: json.quantity?.total?.value, isUnlimited: json.quantity?.total?.is_unlimited };
            this.quantityPerOrder = json.quantity?.per_ticket;
            this.conditions = json.quantity?.conditions;
            this.maximumCanBuy = json.maximum_can_buy;
            this.numberUsed = parseInt(Math.random() * 100);
            this.type = json.status;
            this.price = { value: json.price.value, type: 'perTicket', currency: json.price.currency }
            this.canAttendBy = json.can_attend_by ? json.can_attend_by.filter(c => c.match_type == 'some').map(c => c.values).join(',') : '';
            this.onlyMember = json.only_member;
            this.canScanBy = json.can_scan_by ? json.can_scan_by.filter(c => c.match_type == 'exact').map(c => c.values).join(',') : '';
            this.language = json.primary_language;
            this.ticketTitle = json.title;
            this.issued = json.issued;
        } else {
            this.id = null;
            this.saleStart = new Date();
            this.saleEnd = new Date();
            this.validStart = new Date();
            this.validEnd = new Date();
            this.quantityTotal = { value: 1, isUnlimited: false };
            this.quantityPerOrder = 1;
            this.conditions = [];
            this.maximumCanBuy = 1;
            this.numberUsed = 0;
            this.type = 'available';
            this.price = { value: 0, type: 'perTicket', currency: 'THB' }
            this.canAttendBy = '';
            this.onlyMember = true;
            this.canScanBy = 'admin@dtgo.com,admin@whizdomclub.com';
            this.language = 'Thai';
            this.ticketTitle = '';
            this.issued = 0;
        }
    }

    toJSON () {
        return {
            title: this.ticketTitle,
            description: "Everything free except some snack.",
            price: {
                value: parseInt(this.price.value),
                currency: this.price.currency
            },
            maximum_can_buy: this.maximumCanBuy,
            quantity: {
                total: {
                    value: this.quantityTotal.value,
                    is_unlimited: this.quantityTotal.isUnlimited
                },
                per_ticket: this.quantityPerOrder,
                conditions: this.conditions
            },
            entry_period: [
                {
                    start: this.validStart ? this.validStart.toISOString() : null,
                    end: this.validEnd ? this.validEnd.toISOString() : null
                }
            ],
            sales_period: {
                start: this.saleStart ? this.saleStart.toISOString() : null,
                end: this.saleEnd ? this.saleEnd.toISOString() : null
            },
            can_scan_by: [
                // {
                //     "match_type": "exact",
                //     "values": ["staff1@whizdomclub.com", "0898450001"]
                // },
                {
                    match_type: "exact",
                    values: this.canScanBy.split(',')
                }
            ],
            can_attend_by: [
                {
                    match_type: "some",
                    values: this.canAttendBy.split(',')
                },
                // {
                //     "match_type": "some",
                //     "values": ["@dtgo.com", "@whizdomclub.com"]
                // }
            ],
            only_member: this.onlyMember,
            primary_language: this.language,
            status: this.type
        };
    }
}